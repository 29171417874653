import { SettingActionTypes } from './constants';
import {APICore} from "../../helpers/api/apiCore";

const api = new APICore();

const INIT_STATE = {
    loading: {},
    error: {},
    setting: api.getSession()?.setting || {},
    roleChanged: false,
    updated: false,
    option: {},
    trackIds: []
};

type SettingData = {
    id: number;
    name: string;
};

type SettingActionType = {
    type:
        | SettingActionTypes.API_RESPONSE_SUCCESS
        | SettingActionTypes.API_RESPONSE_ERROR
        | SettingActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | SettingActionTypes.CHANGE_ROLE
        | SettingActionTypes.RESET
        | SettingActionTypes.UPDATE
        | SettingActionTypes.GET_OPTION;
    payload: {
        actionType?: string;
        data?: SettingData | any;
        error?: string;
        validationErrors?: [];

        key: string;
        value?: string;

        page?: string;

        trackId: string
    };
};

type State = {
    loading: {}
    trackIds: string[]
};

const Setting = (state: State = INIT_STATE, action: SettingActionType) => {
    switch (action.type) {
        case SettingActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case SettingActionTypes.CHANGE_ROLE: {
                    return {
                        ...state,
                        setting:action.payload.data,
                        loading: {
                            "changeRole": false
                        },
                        error: {
                            "changeRole": ""
                        }
                    };
                }
                case SettingActionTypes.UPDATE: {
                    return {
                        ...state,
                        updated: action.payload.data.result,
                        loading: {
                            "update": false
                        },
                        error: {
                            "update": ""
                        },
                        trackIds: state.trackIds.filter(e => e !== action.payload.data.trackId)
                    };
                }
                case SettingActionTypes.GET_OPTION: {
                    return {
                        ...state,
                        option: action.payload.data,
                        loading: {
                            "getOption": false
                        },
                        error: {
                            "getOption": ""
                        }
                    };
                }
                default:
                    return { ...state };
            }

        case SettingActionTypes.API_RESPONSE_ERROR:

            switch (action.payload.actionType) {

                case SettingActionTypes.CHANGE_ROLE:
                {
                    return {
                        ...state,
                        error: {
                            "changeRole": action.payload.error
                        },
                        loading: {
                            "changeRole": false
                        },
                    };
                }
                case SettingActionTypes.UPDATE:
                {
                    return {
                        ...state,
                        error: {
                            "update": action.payload.error
                        },
                        loading: {
                            "update": false
                        },
                    };
                }
                case SettingActionTypes.GET_OPTION:
                {
                    return {
                        ...state,
                        error: {
                            "getOption": action.payload.error
                        },
                        loading: {
                            "getOption": false
                        },
                    };
                }

                default:
                    return { ...state };
            }

        case SettingActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case SettingActionTypes.CHANGE_ROLE:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "changeRole": action.payload.validationErrors
                        },
                        loading: {
                            "changeRole": false
                        },
                    };
                }
                case SettingActionTypes.UPDATE:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "update": action.payload.validationErrors
                        },
                        loading: {
                            "update": false
                        },
                    };
                }
                case SettingActionTypes.GET_OPTION: {
                    return {
                        ...state,
                        validationErrors: {
                            "getOption": action.payload.validationErrors
                        },
                        loading: {
                            "getOption": false
                        },
                    };
                }
                default:
                    return { ...state };
            }

        case SettingActionTypes.CHANGE_ROLE:

            return {
                ...state,
                loading: {
                    "changeRole": true
                },
                error: {
                    "changeRole": ""
                }
            };

        case SettingActionTypes.UPDATE:

            return {
                ...state,
                loading: {
                    "update": true
                },
                error: {
                    "update": ""
                },
                trackIds: [
                    ...state.trackIds,
                    action.payload.trackId
                ]
            };

        case SettingActionTypes.GET_OPTION:

            return {
                ...state,
                loading: {
                    "getOption": true
                },
                error: {
                    "getOption": ""
                }
            };

        case SettingActionTypes.RESET:

            return {
                ...state,
                pageChanged: false,
                updated: false,
                error: {},
                loading: {},
                option: {}
            };

        default:
            return { ...state };
    }
};

export default Setting;

import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {graphql} from '../../../helpers';
import {AccountSecurityActionTypes, AccountSecurityErrors} from './constants';
import {checkResponseError, checkServerError, pluckResponse} from "../../../helpers/functions";
import {accountSecurityActions} from "./actions";
import { settingActions } from '../../setting/actions';

type AccountSecurityData = {
    payload: {

        queryParams: {
            limit: number;
            page: number;

            name: string;
            registeredFrom: string;
            registeredTo: string;
            status: string;
        }

        token: string,

        file: {fileId: string} & File;

        data: any
    };
    type: string;
};

function* changePassword({ payload: {data} }: AccountSecurityData): SagaIterator {
    try {
        const query = {
            // Mutation string
            'query': `mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
                changePassword(currentPassword: $currentPassword, newPassword: $newPassword)
            }`,
            'variables': {
                ...data
            },
            operationName: "ChangePassword"
        };

        const response: any = yield call(graphql, query, 'auth');

        checkResponseError(response, AccountSecurityErrors.RESPONSE_200);

        const rCP = response.data;

        checkServerError(rCP);

        pluckResponse(rCP, "changePassword");

        yield put(accountSecurityActions.apiResponseSuccess(AccountSecurityActionTypes.CHANGE_PASSWORD, true));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(accountSecurityActions.apiResponseError(AccountSecurityActionTypes.CHANGE_PASSWORD, error));
        } else {
            yield put(accountSecurityActions.apiResponseValidationErrors(AccountSecurityActionTypes.CHANGE_PASSWORD, error));
        }

    }
}

function* lastPasswordUpdated(): SagaIterator {
    try {
        const query = {
            'query': `query LastPasswordUpdated {
                lastPasswordUpdated
            }`,
            operationName: "LastPasswordUpdated"
        };

        const response: any = yield call(graphql, query, 'auth');

        checkResponseError(response, AccountSecurityErrors.RESPONSE_200);

        const rLPU = response.data;

        checkServerError(rLPU);

        const lastPasswordUpdated = pluckResponse(rLPU, "lastPasswordUpdated");

        yield put(accountSecurityActions.apiResponseSuccess(AccountSecurityActionTypes.LAST_PASSWORD_UPDATED, lastPasswordUpdated));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(accountSecurityActions.apiResponseError(AccountSecurityActionTypes.LAST_PASSWORD_UPDATED, error));
        } else {
            yield put(accountSecurityActions.apiResponseValidationErrors(AccountSecurityActionTypes.LAST_PASSWORD_UPDATED, error));
        }

    }
}

function* create2faSecret(): SagaIterator {
    try {
        const query = {
            'query': `mutation Active2faSecret {
                active2faSecret
            }`,
            operationName: "Active2faSecret"
        };

        const response: any = yield call(graphql, query, 'auth');

        checkResponseError(response, AccountSecurityErrors.RESPONSE_200);

        const rG = response.data;

        checkServerError(rG);

        const active2faSecret = pluckResponse(rG, "active2faSecret");

        yield put(accountSecurityActions.apiResponseSuccess(AccountSecurityActionTypes.CREATE_GOOGLE_2FA, active2faSecret));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(accountSecurityActions.apiResponseError(AccountSecurityActionTypes.CREATE_GOOGLE_2FA, error));
        } else {
            yield put(accountSecurityActions.apiResponseValidationErrors(AccountSecurityActionTypes.CREATE_GOOGLE_2FA, error));
        }

    }
}
function* verifyGoogle2Fa({ payload: {data} }: AccountSecurityData): SagaIterator {
    try {
        const query = {
            'query': `mutation VerifyGoogle2Fa($otp: String!, $twoFaOtp: String!) {
                verifyGoogle2Fa(otp: $otp, twoFaOtp: $twoFaOtp)
            }`,
            'variables': {
                ...data
            },
            operationName: "VerifyGoogle2Fa"
        };

        const response: any = yield call(graphql, query, 'auth');

        checkResponseError(response, AccountSecurityErrors.RESPONSE_200);

        const rVG = response.data;

        checkServerError(rVG);

        const verifyGoogle2Fa = pluckResponse(rVG, "verifyGoogle2Fa");

        yield put(accountSecurityActions.apiResponseSuccess(AccountSecurityActionTypes.VERIFY_GOOGLE_2FA, verifyGoogle2Fa));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(accountSecurityActions.apiResponseError(AccountSecurityActionTypes.VERIFY_GOOGLE_2FA, error));
        } else {
            yield put(accountSecurityActions.apiResponseValidationErrors(AccountSecurityActionTypes.VERIFY_GOOGLE_2FA, error));
        }
    }
}

function* disableGoogle2Fa(): SagaIterator {
    try {
        const query = {
            'query': `mutation DisableGoogle2Fa {
                disableGoogle2Fa
            }`,
            operationName: "DisableGoogle2Fa"
        };

        const response: any = yield call(graphql, query, 'auth');

        checkResponseError(response, AccountSecurityErrors.RESPONSE_200);

        const rVG = response.data;

        checkServerError(rVG);

        const disableGoogle2Fa = pluckResponse(rVG, "disableGoogle2Fa");

        yield put(settingActions.refreshUser());

        yield put(accountSecurityActions.apiResponseSuccess(AccountSecurityActionTypes.DISABLE_GOOGLE_2FA, disableGoogle2Fa));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(accountSecurityActions.apiResponseError(AccountSecurityActionTypes.DISABLE_GOOGLE_2FA, error));
        } else {
            yield put(accountSecurityActions.apiResponseValidationErrors(AccountSecurityActionTypes.DISABLE_GOOGLE_2FA, error));
        }
    }
}

function* verifyDisableGoogle2Fa({ payload: {data} }: AccountSecurityData): SagaIterator {
    try {
        const query = {
            'query': `mutation VerifyDisableGoogle2Fa($otp: String!) {
                verifyDisableGoogle2Fa(otp: $otp)
            }`,
            'variables': {
                ...data
            },
            operationName: "VerifyDisableGoogle2Fa"
        };

        const response: any = yield call(graphql, query, 'auth');

        checkResponseError(response, AccountSecurityErrors.RESPONSE_200);

        const rVG = response.data;

        checkServerError(rVG);

        const verifyDisableGoogle2Fa = pluckResponse(rVG, "verifyDisableGoogle2Fa");

        yield put(settingActions.refreshUser());

        yield put(accountSecurityActions.apiResponseSuccess(AccountSecurityActionTypes.VERIFY_DISABLE_GOOGLE_2FA, verifyDisableGoogle2Fa));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(accountSecurityActions.apiResponseError(AccountSecurityActionTypes.VERIFY_DISABLE_GOOGLE_2FA, error));
        } else {
            yield put(accountSecurityActions.apiResponseValidationErrors(AccountSecurityActionTypes.VERIFY_DISABLE_GOOGLE_2FA, error));
        }
    }
}

export function* watchChangePassword(): any {
    yield takeEvery(AccountSecurityActionTypes.CHANGE_PASSWORD, changePassword);
}

export function* watchLastPasswordUpdated(): any {
    yield takeEvery(AccountSecurityActionTypes.LAST_PASSWORD_UPDATED, lastPasswordUpdated);
}

export function* watchCreate2faSecret(): any {
    yield takeEvery(AccountSecurityActionTypes.CREATE_GOOGLE_2FA, create2faSecret);
}

export function* watchVerifyGoogle2Fa(): any {
    yield takeEvery(AccountSecurityActionTypes.VERIFY_GOOGLE_2FA, verifyGoogle2Fa);
}

export function* watchDisableGoogle2Fa(): any {
    yield takeEvery(AccountSecurityActionTypes.DISABLE_GOOGLE_2FA, disableGoogle2Fa);
}

export function* watchVerifyDisableGoogle2Fa(): any {
    yield takeEvery(AccountSecurityActionTypes.VERIFY_DISABLE_GOOGLE_2FA, verifyDisableGoogle2Fa);
}

function* accountSecuritySaga() {
    yield all([
        fork(watchChangePassword),
        fork(watchLastPasswordUpdated),
        fork(watchCreate2faSecret),
        fork(watchVerifyGoogle2Fa),
        fork(watchDisableGoogle2Fa),
        fork(watchVerifyDisableGoogle2Fa),
    ]);
}

export default accountSecuritySaga;

export enum CashIncomeActionTypes {
    API_RESPONSE_SUCCESS = '@@cashIncome/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@cashIncome/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@cashIncome/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@cashIncome/RESET',

    ORDERS = '@@cashIncome/ORDERS',
    ORDER = '@@cashIncome/ORDER',
    CREATE = '@@cashIncome/CREATE',
    UPDATE = '@@cashIncome/UPDATE',
    SEARCH = '@@cashIncome/SEARCH',

    REVIEW = '@@cashIncome/REVIEW',

    CANCEL = '@@cashIncome/CANCEL',
    UPDATE_SETTING = '@@cashIncome/UPDATE_SETTING',
    GET_SETTING = '@@cashIncome/GET_SETTING',
}

export enum CashIncomeErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.'
}




import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {OrderSettingActionTypes, OrderSettingErrors} from "./constants";
import {orderSettingActions} from "./actions";
import {graphql} from "../../helpers";
import { SagaIterator } from "@redux-saga/core";
import { checkResponseError, checkServerError, pluckResponse } from "../../helpers/functions";

type OrderSettingData = {
    payload: {
        id: string;

        limit: string;
        page: string;

        data: any,
        trackId: string
    };
    type: string;
};

function* getOption({ payload: {data} }: OrderSettingData): SagaIterator {
    try {
        const param = {
            query:`query OrderOptions($options: [String]) {
                orderOptions(options: $options) {
                    cash_income_status
                    intl_payment_status
                    cash_income_payment_methods {
                        paymentMethod
                        currencyCat
                        cashIncomeFee
                        intlPaymentFee
                    }
                    intl_payment_methods {
                        paymentMethod
                        currencyCat
                        cashIncomeFee
                        intlPaymentFee
                    }
                }
            }`,

            variables: {
                options: data
            },

            operationName: "OrderOptions"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderSettingErrors.RESPONSE_200);

        const rUA = response.data;

        checkServerError(rUA);

        const option = pluckResponse(rUA, "orderOptions");

        yield put(orderSettingActions.apiResponseSuccess(OrderSettingActionTypes.GET_OPTIONS, option));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderSettingActions.apiResponseError(OrderSettingActionTypes.GET_OPTIONS, error));
        } else {
            yield put(orderSettingActions.apiResponseValidationErrors(OrderSettingActionTypes.GET_OPTIONS, error));
        }
    }
}

function* watchGetOption() {
    yield takeLatest(OrderSettingActionTypes.GET_OPTIONS, getOption);
}

function* orderSettingSaga() {
    yield all([
        fork(watchGetOption),
    ]);
}

export default orderSettingSaga;

import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { graphql } from 'helpers';
import {LogActionTypes, LogErrors} from './constants';
import {
    checkResponseError,
    checkServerError,
    pluckResponse,
} from "../../helpers/functions";
import {logActions} from "./actions";

type LogData = {
    payload: {

        queryParams: {
            limit: string;
            page: string;
            orderBy: string;
            sort: string;
        };

        data: any;
        id: any;
    };
    type: string;
};

function* getLogs({ payload: { queryParams } }: LogData): SagaIterator {

    try {
        const param = {
            query:`query Logs($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $userId: String) {
                logs(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, userId: $userId) {
                    data {
                        id
                        title
                        description
                        created_at
                        user {
                            display_name
                        }
                        done_by_user {
                            display_name
                        }
                        done_by
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            },
            operationName: "Logs"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, LogErrors.RESPONSE_200);

        const rLogs = response.data;

        checkServerError(rLogs);

        const logs = pluckResponse(rLogs, "logs");

        const result = {
            logs,
            queryParams
        }

        yield put(logActions.apiResponseSuccess(LogActionTypes.GET_LOGS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(logActions.apiResponseError(LogActionTypes.GET_LOGS, error));
        } else {
            yield put(logActions.apiResponseValidationErrors(LogActionTypes.GET_LOGS, error));
        }
    }
}

function* getLog({ payload: { id } }: LogData): SagaIterator {

    try {
        const param = {
            query:`query Log($id: String!) {
                log(id: $id) {
                    id
                    title
                    description
                    created_at
                    user {
                        display_name
                    }
                    done_by_user {
                        display_name
                    }
                    done_by
                }
            }`,

            variables: {
                id: id
            },
            operationName: "Log"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, LogErrors.RESPONSE_200);

        const rT = response.data;

        checkServerError(rT);

        const log = pluckResponse(rT, "log");

        yield put(logActions.apiResponseSuccess(LogActionTypes.GET_LOG, log));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(logActions.apiResponseError(LogActionTypes.GET_LOG, error));
        } else {
            yield put(logActions.apiResponseValidationErrors(LogActionTypes.GET_LOG, error));
        }
    }
}

function* searchLog({ payload: { queryParams } }: LogData): SagaIterator {
    try {
        const param = {
            query:`query Logs(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $userId: String
            ) {
                logs(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort,
                    userId: $userId
                ) {
                    data {
                        id
                        title
                        description
                        created_at
                        user {
                            display_name
                        }
                        done_by_user {
                            display_name
                        }
                        done_by
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            },
            operationName: "Logs"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, LogErrors.RESPONSE_200);

        const rLogs = response.data;

        checkServerError(rLogs);

        const logs = pluckResponse(rLogs, "logs");

        const result = {
            logs,
            queryParams
        }

        yield put(logActions.apiResponseSuccess(LogActionTypes.SEARCH_LOG, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(logActions.apiResponseError(LogActionTypes.SEARCH_LOG, error));
        } else {
            yield put(logActions.apiResponseValidationErrors(LogActionTypes.SEARCH_LOG, error));
        }
    }
}


export function* watchGetLogs() {
    yield takeEvery(LogActionTypes.GET_LOGS, getLogs);
}

export function* watchGetLog() {
    yield takeEvery(LogActionTypes.GET_LOG, getLog);
}

export function* watchSearchLog() {
    yield takeEvery(LogActionTypes.SEARCH_LOG, searchLog);
}


function* logsSaga() {
    yield all([
        fork(watchGetLogs),
        fork(watchGetLog),
        fork(watchSearchLog),
    ]);
}

export default logsSaga;

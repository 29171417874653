import {OrderSettingActionTypes} from './constants';

export type OrderSettingActionType = {
    type:
        | OrderSettingActionTypes.API_RESPONSE_SUCCESS
        | OrderSettingActionTypes.API_RESPONSE_ERROR
        | OrderSettingActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | OrderSettingActionTypes.RESET
        | OrderSettingActionTypes.GET_OPTIONS;

    payload: {} | string;
};

type OrderSetting = {
    id: number;
    name: string;
    guard_name: string;
};

class OrderSettingActions {

    apiResponseSuccess = (actionType: string, data: OrderSetting | {}): OrderSettingActionType => ({
        type: OrderSettingActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string, trackId: string = ""): OrderSettingActionType => ({
        type: OrderSettingActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error, trackId },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: [], trackId: string = ""): OrderSettingActionType => ({
        type: OrderSettingActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors, trackId },
    });

    reset = (): OrderSettingActionType => ({
        type: OrderSettingActionTypes.RESET,
        payload: {},
    });

    getOptions = (data: any): OrderSettingActionType => ({
        type: OrderSettingActionTypes.GET_OPTIONS,
        payload: { data },
    });
}

const orderSettingActions = new OrderSettingActions();
export { orderSettingActions };

export enum SettingActionTypes {
    API_RESPONSE_SUCCESS = '@@setting/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@setting/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@setting/API_RESPONSE_VALIDATION_ERRORS',
    SET_PREVIOUS_PAGE = '@@setting/SET_PREVIOUS_PAGE',

    CHANGE_ROLE = '@@setting/CHANGE_ROLE',
    REFRESH_ROLE = '@@setting/REFRESH_ROLE',

    RESET = '@@setting/RESET',

    GET_USER_ACTION = '@@setting/GET_USER_ACTION',
    STOP_GET_USER_ACTION = '@@setting/STOP_GET_USER_ACTION',

    UPDATE = '@@setting/UPDATE',
    GET_OPTION = '@@setting/GET_OPTION',
}

export enum SettingErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.'
}




import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {IntlPaymentActionTypes, IntlPaymentErrors} from "./constants";
import {intlPaymentActions} from "./actions";
import {graphql} from "../../helpers";
import {SagaIterator} from "@redux-saga/core";
import {checkResponseError, checkServerError, pluckResponse} from "../../helpers/functions";

type IntlPaymentData = {
    payload: {
        id: string;

        limit: string;
        page: string;

        queryParams: {
            limit: string;
            page: string;
            intlPaymentBy: string;
            sort: string;
        };

        data: any;
        trackId: string;
    };
    type: string;
};

function* orders({ payload: { queryParams } }: IntlPaymentData): SagaIterator {
    try {
        const param = {
            query:`query Orders {
                orders {
                    id
                    user {
                        username
                    }
                    created_at
                    updated_at
                    amount
                    rate
                    fee
                    discount
                    data
                    name
                    final_amount
                    status
                }
            }`,

            variables: {
                ...queryParams
            },
            operationName: "Orders"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IntlPaymentErrors.RESPONSE_200);

        const rIntlPayment = response.data;

        checkServerError(rIntlPayment);

        const orders = pluckResponse(rIntlPayment, "orders");

        yield put(intlPaymentActions.apiResponseSuccess(IntlPaymentActionTypes.ORDERS, orders));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(intlPaymentActions.apiResponseError(IntlPaymentActionTypes.ORDERS, error));
        } else {
            yield put(intlPaymentActions.apiResponseValidationErrors(IntlPaymentActionTypes.ORDERS, error));
        }
    }
}

function* order({ payload: { queryParams } }: IntlPaymentData): SagaIterator {
    try {
        const param = {
            query:`query OrderIntlPayment($id: String!) {
                orderIntlPayment(id: $id) {
                    id
                    user {
                        id
                        username
                    }
                    created_at
                    updated_at
                    amount
                    currency
                    url
                    data {
                        username
                        password
                    }
                    rate
                    fee
                    discount
                    name
                    final_amount
                    status
                    bills {
                        id
                        amount
                        currency
                        status
                        created_at
                    }
                    notes {
                        note
                        created_at
                    }
                    transactions {
                        transaction {
                            transaction_id
                            type
                            status
                            name
                            amountFloat
                            wallet {
                                name
                            }
                            created_at
                        }
                    }
                }
            }`,

            variables: {
                ...queryParams
            },
            operationName: "OrderIntlPayment"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IntlPaymentErrors.RESPONSE_200);

        const rIntlPayment = response.data;

        checkServerError(rIntlPayment);

        const order = pluckResponse(rIntlPayment, "orderIntlPayment");

        yield put(intlPaymentActions.apiResponseSuccess(IntlPaymentActionTypes.ORDER, order));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(intlPaymentActions.apiResponseError(IntlPaymentActionTypes.ORDER, error));
        } else {
            yield put(intlPaymentActions.apiResponseValidationErrors(IntlPaymentActionTypes.ORDER, error));
        }
    }
}

function* create({ payload: {data} }: IntlPaymentData): SagaIterator {
    try {
        const param = {

            query:`mutation CreateIntlPayment(
                $amount: String!,
                $currency: String!,
                $url: String!,
                $username: String,
                $password: String,
                $acceptConditions: String!,
                $descriptions: String,
            ) {
                createIntlPayment(
                    amount: $amount,
                    currency: $currency,
                    url: $url,
                    username: $username,
                    password: $password,
                    acceptConditions: $acceptConditions,
                    descriptions: $descriptions,
                )
            }`,

            variables: { ...data },
            operationName: "CreateIntlPayment"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IntlPaymentErrors.RESPONSE_200);

        const rIntlPayment = response.data;

        checkServerError(rIntlPayment);

        const createIntlPayment = pluckResponse(rIntlPayment, "createIntlPayment");

        yield put(intlPaymentActions.apiResponseSuccess(IntlPaymentActionTypes.CREATE, createIntlPayment));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(intlPaymentActions.apiResponseError(IntlPaymentActionTypes.CREATE, error));
        } else {
            yield put(intlPaymentActions.apiResponseValidationErrors(IntlPaymentActionTypes.CREATE, error));
        }
    }
}

function* update({ payload: {data} }: IntlPaymentData): SagaIterator {
    try {
        const param = {

            query:`mutation UpdateIntlPayment(
                $id: String!,
                $amount: String!,
                $currency: String!,
                $url: String!,
                $username: String,
                $password: String,
                $descriptions: String,
                $status: String!,
                $note: String,
                $updateRate: Boolean,
            ) {
                updateIntlPayment(
                    id: $id,
                    amount: $amount,
                    currency: $currency,
                    url: $url,
                    username: $username,
                    password: $password,
                    descriptions: $descriptions,
                    status: $status,
                    note: $note,
                    updateRate: $updateRate,
                )
            }`,

            variables: { ...data },
            operationName: "UpdateIntlPayment"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IntlPaymentErrors.RESPONSE_200);

        const rIntlPayment = response.data;

        checkServerError(rIntlPayment);

        pluckResponse(rIntlPayment, "updateIntlPayment");

        yield put(intlPaymentActions.apiResponseSuccess(IntlPaymentActionTypes.UPDATE, true));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(intlPaymentActions.apiResponseError(IntlPaymentActionTypes.UPDATE, error));
        } else {
            yield put(intlPaymentActions.apiResponseValidationErrors(IntlPaymentActionTypes.UPDATE, error));
        }
    }
}

function* cancel({ payload: {id} }: IntlPaymentData): SagaIterator {
    try {
        const param = {

            query:`mutation CancelIntlPayment(
                $id: String!
            ) {
                cancelIntlPayment(
                    id: $id
                )
            }`,

            variables: {
                id
            },
            operationName: "CancelIntlPayment"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IntlPaymentErrors.RESPONSE_200);

        const rIntlPayment = response.data;

        checkServerError(rIntlPayment);

        pluckResponse(rIntlPayment, "cancelIntlPayment");

        yield put(intlPaymentActions.apiResponseSuccess(IntlPaymentActionTypes.CANCEL, id));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(intlPaymentActions.apiResponseError(IntlPaymentActionTypes.CANCEL, error));
        } else {
            yield put(intlPaymentActions.apiResponseValidationErrors(IntlPaymentActionTypes.CANCEL, error));
        }
    }
}

function* review({ payload: { data } }: IntlPaymentData): SagaIterator {
    try {
        const param = {
            query:`query IntlPaymentReview($amount: String!, $currency: String!) {
                intlPaymentReview(amount: $amount, currency: $currency) {
                    amount
                    rate
                    fee
                    final_amount
                }
            }`,

            variables: { ...data },
            operationName: "IntlPaymentReview"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IntlPaymentErrors.RESPONSE_200);

        const rOPR = response.data;

        checkServerError(rOPR);

        const intlPaymentReview = pluckResponse(rOPR, "intlPaymentReview");

        yield put(intlPaymentActions.apiResponseSuccess(IntlPaymentActionTypes.REVIEW, intlPaymentReview));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(intlPaymentActions.apiResponseError(IntlPaymentActionTypes.REVIEW, error));
        } else {
            yield put(intlPaymentActions.apiResponseValidationErrors(IntlPaymentActionTypes.REVIEW, error));
        }
    }
}


function* updateIPSetting({ payload: { data, trackId } }: IntlPaymentData): SagaIterator {

    try {

        const paymentMethods = data.paymentMethods || [];

        // Transform the array of objects into separate arrays for each field
        const paymentMethod = paymentMethods.map((item: any) => item.paymentMethod);
        const currencyCat = paymentMethods.map((item: any) => item.currencyCat);
        const intlPaymentFee = paymentMethods.map((item: any) => item.intlPaymentFee);


        const param = {
            query:`mutation UpdateIPSetting(
                $paymentMethod: [String!]
                $currencyCat: [String!]
                $intlPaymentFee: [Float!]
            ) {
                updateIPSetting(
                    paymentMethod: $paymentMethod
                    currencyCat: $currencyCat
                    intlPaymentFee: $intlPaymentFee
                )
            }`,

            variables: {
                paymentMethod,
                currencyCat,
                intlPaymentFee,
            },

            operationName: "UpdateIPSetting"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IntlPaymentErrors.RESPONSE_200);

        const responseCR = response.data;

        checkServerError(responseCR);

        pluckResponse(responseCR, "updateIPSetting");

        yield put(intlPaymentActions.apiResponseSuccess(IntlPaymentActionTypes.UPDATE_SETTING, {
            result: true,
            trackId: trackId
        }));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(intlPaymentActions.apiResponseError(IntlPaymentActionTypes.UPDATE_SETTING, error, trackId));
        } else {
            yield put(intlPaymentActions.apiResponseValidationErrors(IntlPaymentActionTypes.UPDATE_SETTING, error, trackId));
        }
    }
}


function* getIPSetting(): SagaIterator {

    try {

        const param = {
            query:`mutation GetIPSetting {
                getIPSetting
            }`,

            operationName: "GetIPSetting"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IntlPaymentErrors.RESPONSE_200);

        const responseCR = response.data;

        checkServerError(responseCR);

        const getIPSetting = pluckResponse(responseCR, "getIPSetting");

        yield put(intlPaymentActions.apiResponseSuccess(IntlPaymentActionTypes.GET_SETTING, getIPSetting));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(intlPaymentActions.apiResponseError(IntlPaymentActionTypes.GET_SETTING, error));
        } else {
            yield put(intlPaymentActions.apiResponseValidationErrors(IntlPaymentActionTypes.GET_SETTING, error));
        }
    }
}

export function* watchOrders() {
    yield takeEvery(IntlPaymentActionTypes.ORDERS, orders);
}

export function* watchOrder() {
    yield takeEvery(IntlPaymentActionTypes.ORDER, order);
}

export function* watchInsert() {
    yield takeEvery(IntlPaymentActionTypes.CREATE, create);
}

export function* watchUpdate() {
    yield takeEvery(IntlPaymentActionTypes.UPDATE, update);
}

export function* watchCancel() {
    yield takeEvery(IntlPaymentActionTypes.CANCEL, cancel);
}

export function* watchReview() {
    yield takeEvery(IntlPaymentActionTypes.REVIEW, review);
}

export function* watchUpdateIPSetting() {
    yield takeEvery(IntlPaymentActionTypes.UPDATE_SETTING, updateIPSetting);
}

export function* watchGetIPSetting() {
    yield takeEvery(IntlPaymentActionTypes.GET_SETTING, getIPSetting);
}

function* intlPaymentSaga() {
    yield all([
        fork(watchOrders),
        fork(watchOrder),
        fork(watchInsert),
        fork(watchUpdate),
        fork(watchCancel),
        fork(watchReview),
        fork(watchUpdateIPSetting),
        fork(watchGetIPSetting),
    ]);
}

export default intlPaymentSaga;

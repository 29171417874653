import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { checkResponseError, checkServerError, pluckResponse } from '../../helpers/functions';
import { graphql, graphqlUpload } from '../../helpers';
import { nCFrontActions } from './actions';
import { NCardFrontActionTypes, NCardFrontErrors } from './constants';
import { END, eventChannel } from 'redux-saga';

type NCFrontData = {
    payload: {
        id: number,

        data: any;

        queryParams: {
            limit: number;
            page: number;

        }

        token: string;

        file: {fileId: string} & File;

        fileId: string;
        uuid: string;
    };
    type: string;
};

function createUploader(file: File, query:any) {

    let emit: any;

    const chan = eventChannel((emitter) => {
        emit = emitter;
        return () => {};
    });

    const uploadProgressCb = ({ total, loaded }: any) => {
        const percentage = Math.round((loaded * 100) / total);
        emit(percentage);
        if (percentage === 100) emit(END);
    };

    // make form object
    let data = new FormData();
    data.set('operations', JSON.stringify(query));

    data.set('operationName', "UploadNcFront1");
    data.set('map', JSON.stringify({"file":["variables.file"]}));
    data.append('file', file);

    const uploadPromise = graphqlUpload(data, 'auth', uploadProgressCb);
    return [uploadPromise, chan];
}

function* uploadNcFrontProgressWatcher(chan:any, fileId: string): SagaIterator {
    while (true) {
        const progress = yield take(chan);
        yield put(nCFrontActions.uploadNationalCardFrontProgress(progress, fileId));
    }
}

function* uploadNcFront({ payload: {id, file} }: NCFrontData): SagaIterator {
    try {
        const query = {
            // Mutation string
            'query': `mutation UploadNcFront($id: String!, $file: Upload!) {
                uploadNcFront(id: $id, file: $file) {
                    uuid
                }
            }`,
            'variables': {
                "file": file,
                "id": id,
            },
            operationName: "UploadNcFront"
        };

        const [uploadPromise, chan] = yield call(createUploader, file, query);
        yield fork(uploadNcFrontProgressWatcher, chan, file.fileId);

        const response = yield call(() => uploadPromise);

        checkResponseError(response, NCardFrontErrors.RESPONSE_200);

        const rUNF = response.data;

        checkServerError(rUNF);

        const uploadNcFront = pluckResponse(rUNF, "uploadNcFront");

        yield put(nCFrontActions.apiResponseSuccess(NCardFrontActionTypes.UPLOAD_NC_FRONT, {fileId: file.fileId, uploadNcFront}));

    } catch (error: any) {

        if(typeof error === "string") {
            yield put(nCFrontActions.apiResponseError(NCardFrontActionTypes.UPLOAD_NC_FRONT, {fileId: file.fileId, error}));
        } else {
            yield put(nCFrontActions.apiResponseValidationErrors(NCardFrontActionTypes.UPLOAD_NC_FRONT, {fileId: file.fileId, error}));
        }
    }
}

function* update({ payload: {data} }: NCFrontData): SagaIterator {
    try {
        const param = {
            query:`mutation UpdateNcFront($id: String!, $status: String!) {
                updateNcFront(id: $id, status: $status)
            }`,
            variables: { ...data },
            operationName: "UpdateNcFront"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, NCardFrontErrors.RESPONSE_200);

        const rUNF = response.data;

        checkServerError(rUNF);

        const updateNcFront = pluckResponse(rUNF, "updateNcFront");

        yield put(nCFrontActions.apiResponseSuccess(NCardFrontActionTypes.UPDATE, updateNcFront));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(nCFrontActions.apiResponseError(NCardFrontActionTypes.UPDATE, error));
        } else {
            yield put(nCFrontActions.apiResponseValidationErrors(NCardFrontActionTypes.UPDATE, error));
        }
    }
}

function* deleteNcFront({ payload: {id, fileId, uuid} }: NCFrontData): SagaIterator {
    try {
        if(uuid) {

            const param = {
                query:`mutation DeleteNcFront($id: String!, $uuid: String!) {
                    deleteNcFront(id: $id, uuid: $uuid)
                }`,
                variables: {
                    "uuid": uuid,
                    "id": id,
                },
                operationName: "DeleteNcFront"
            };

            const response: any = yield call(graphql, param, 'auth');

            checkResponseError(response, NCardFrontErrors.RESPONSE_200);

            const rDNF = response.data;

            checkServerError(rDNF);

            pluckResponse(rDNF, "deleteNcFront");
        }

        yield put(nCFrontActions.apiResponseSuccess(NCardFrontActionTypes.DELETE_NC_FRONT, fileId));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(nCFrontActions.apiResponseError(NCardFrontActionTypes.DELETE_NC_FRONT, error));
        } else {
            yield put(nCFrontActions.apiResponseValidationErrors(NCardFrontActionTypes.DELETE_NC_FRONT, error));
        }
    }
}

export function* watchUploadNcFront(): any {
    yield takeEvery(NCardFrontActionTypes.UPLOAD_NC_FRONT, uploadNcFront);
}

export function* watchUpdateNcFront(): any {
    yield takeEvery(NCardFrontActionTypes.UPDATE, update);
}

export function* watchDeleteNcFront(): any {
    yield takeEvery(NCardFrontActionTypes.DELETE_NC_FRONT, deleteNcFront);
}

function* nCFrontSaga() {
    yield all([
        fork(watchUploadNcFront),
        fork(watchUpdateNcFront),
        fork(watchDeleteNcFront),
    ]);
}

export default nCFrontSaga;

import { all, fork, put, takeEvery, call, take } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {graphql, graphqlUpload} from '../../../helpers';
import {ProfileImageActionTypes, ProfileImageErrors} from './constants';
import {checkResponseError, checkServerError, formatBytes, pluckResponse} from "../../../helpers/functions";
import {profileImageActions} from "./actions";
import {END, eventChannel} from "redux-saga";
import {APICore} from "../../../helpers/api/apiCore";

type ProfileImageData = {
    payload: {

        queryParams: {
            limit: number;
            page: number;

            name: string;
            registeredFrom: string;
            registeredTo: string;
            status: string;
        }

        token: string,

        file: {fileId: string} & File;

        data: any
    };
    type: string;
};

function createUploader(query: any, file: File) {

    let emit: any;

    const chan = eventChannel((emitter) => {
        emit = emitter;
        return () => {};
    });

    const uploadProgressCb = ({ total, loaded }: any) => {
        const percentage = Math.round((loaded * 100) / total);
        emit(percentage);
        if (percentage === 100) emit(END);
    };

    // make form object
    let data = new FormData();
    data.set('operations', JSON.stringify(query));

    data.set('operationName', "");
    data.set('map', JSON.stringify({"file":["variables.file"]}));
    data.append('file', file);

    const uploadPromise = graphqlUpload(data, 'auth', uploadProgressCb);
    return [uploadPromise, chan];
}

function* uploadProgressWatcher(chan:any, fileId: string): SagaIterator {
    while (true) {
        const progress = yield take(chan);
        yield put(profileImageActions.uploadFilesProgress(progress, fileId));
    }
}

function* getProfileImage(): SagaIterator {
    try {
        const param:any = {
            query: `query UserProfileImage{
                userProfileImage {
                    name
                    preview: url(size: "thumbnail")
                    formattedSize: size
                    type: mime_type
                }
            }`,
            operationName: "UserProfileImage"
        };

        // f.preview blob:http://localhost:3000/6f50e966-594e-4204-9f2c-65020cc998e0
        // f.name Screenshot 2023-10-25 114422.png
        // f.type image/png
        // f.formattedSize 13.95 KB

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ProfileImageErrors.RESPONSE_200);

        const rUPI = response.data;

        checkServerError(rUPI);

        let userProfileImage = pluckResponse(rUPI, "userProfileImage");

        userProfileImage = userProfileImage.map((v: any) => {
            v.formattedSize = formatBytes(v.formattedSize);
            v.uploaded = true;
            v.progress = 0;
            return v;
        });

        yield put(profileImageActions.apiResponseSuccess(ProfileImageActionTypes.GET_PROFILE_IMAGE, userProfileImage));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(profileImageActions.apiResponseError(ProfileImageActionTypes.GET_PROFILE_IMAGE, error));
        } else {
            yield put(profileImageActions.apiResponseValidationErrors(ProfileImageActionTypes.GET_PROFILE_IMAGE, error));
        }
    }
}

function* uploadProfileImage({ payload: {file} }: ProfileImageData): SagaIterator {
    try {
        const query = {
            // Mutation string
            'query': `mutation UploadProfileImage($file: Upload!) {
                    uploadProfileImage(file: $file) {
                        id
                        url
                    }
                }`,
            'variables': {
                "file": file,
            },
            operationName: "UploadProfileImage"
        };

        const [uploadPromise, chan] = yield call(createUploader, query, file);
        yield fork(uploadProgressWatcher, chan, file.fileId);
        const response = yield call(() => uploadPromise);

        checkResponseError(response, ProfileImageErrors.RESPONSE_200);

        const rUPI = response.data;

        checkServerError(rUPI);

        const uploadProfileImage = pluckResponse(rUPI, "uploadProfileImage");

        // update session
        const api = new APICore();
        const session = api.getSession();
        session.user.image = uploadProfileImage.url
        api.setSession(session);

        yield put(profileImageActions.apiResponseSuccess(ProfileImageActionTypes.UPLOAD_PROFILE_IMAGE, {fileId: file.fileId, uploadProfileImage}));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(profileImageActions.apiResponseError(ProfileImageActionTypes.UPLOAD_PROFILE_IMAGE, {fileId: file.fileId, error}));
        } else {
            yield put(profileImageActions.apiResponseValidationErrors(ProfileImageActionTypes.UPLOAD_PROFILE_IMAGE, {fileId: file.fileId, error}));
        }

    }
}

export function* watchGetProfileImage(): any {
    yield takeEvery(ProfileImageActionTypes.GET_PROFILE_IMAGE, getProfileImage);
}

export function* watchUploadProfileImage(): any {
    yield takeEvery(ProfileImageActionTypes.UPLOAD_PROFILE_IMAGE, uploadProfileImage);
}

function* profileImageSaga() {
    yield all([
        fork(watchGetProfileImage),
        fork(watchUploadProfileImage),
    ]);
}

export default profileImageSaga;

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { graphql } from '../../helpers';
import { SagaIterator } from '@redux-saga/core';
import { checkResponseError, checkServerError, pluckResponse } from '../../helpers/functions';
import { rateActions } from './actions';
import { RateActionTypes, RateErrors } from './constants';

type RateData = {
    payload: {
        id: number,

        data: any;

        queryParams: {
            limit: number;
            page: number;
        }

        symbol: string;
        symbol1: string;
        symbol2: string;
        provider: string;
    };
    type: string;
};

function* getRates({ payload: {symbol1, symbol2} }: RateData): SagaIterator {

    try {
        const param = {
            query:`query Rates($symbol1: String!, $symbol2: String!) {
                rates(symbol1: $symbol1, symbol2: $symbol2) {
                    price
                    price_buy
                    price_sell
                    created_at
                    date
                    time
                }
            }`,
            variables: {
                "symbol1": symbol1,
                "symbol2": symbol2,
            },
            operationName: "Rates"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, RateErrors.RESPONSE_200);

        const rDP = response.data;

        checkServerError(rDP);

        const rates = pluckResponse(rDP, "rates");

        yield put(rateActions.apiResponseSuccess(RateActionTypes.GET_RATES, rates));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(rateActions.apiResponseError(RateActionTypes.GET_RATES, error));
        } else {
            yield put(rateActions.apiResponseValidationErrors(RateActionTypes.GET_RATES, error));
        }
    }
}

function* getRate({ payload: {data} }: RateData): SagaIterator {

    try {
        const param = {
            query:`query Rate($id: String!) {
                rate(id: $id) {
                    price
                    price_buy
                    price_sell
                    created_at
                    date
                    time
                }
            }`,
            variables: {
                ...data
            },
            operationName: "Rate"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, RateErrors.RESPONSE_200);

        const rDP = response.data;

        checkServerError(rDP);

        const rates = pluckResponse(rDP, "rates");

        yield put(rateActions.apiResponseSuccess(RateActionTypes.GET_RATES, rates));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(rateActions.apiResponseError(RateActionTypes.GET_RATES, error));
        } else {
            yield put(rateActions.apiResponseValidationErrors(RateActionTypes.GET_RATES, error));
        }
    }
}

function* getApiProviderStatus({ payload: {provider} }: RateData): SagaIterator {

    try {
        const param = {
            query:`query ApiProviderStatus($provider: String!) {
                apiProviderStatus(provider: $provider)
            }`,
            variables: {
                "provider": provider,
            },
            operationName: "ApiProviderStatus"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, RateErrors.RESPONSE_200);

        const rDP = response.data;

        checkServerError(rDP);

        const apiProviderStatus = pluckResponse(rDP, "apiProviderStatus");

        yield put(rateActions.apiResponseSuccess(RateActionTypes.GET_API_PROVIDER_STATUS, apiProviderStatus));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(rateActions.apiResponseError(RateActionTypes.GET_API_PROVIDER_STATUS, error));
        } else {
            yield put(rateActions.apiResponseValidationErrors(RateActionTypes.GET_API_PROVIDER_STATUS, error));
        }
    }
}

function* updateApiProviderStatus({ payload: {data} }: RateData): SagaIterator {

    try {
        const param = {
            query:`mutation UpdateApiProviderStatus($provider: String!, $status: String!) {
                updateApiProviderStatus(provider: $provider, status: $status)
            }`,
            variables: {
                ...data
            },
            operationName: "UpdateApiProviderStatus"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, RateErrors.RESPONSE_200);

        const rDP = response.data;

        checkServerError(rDP);

        const updateApiProviderStatus = pluckResponse(rDP, "updateApiProviderStatus");

        yield put(rateActions.apiResponseSuccess(RateActionTypes.UPDATE_API_PROVIDER_STATUS, updateApiProviderStatus));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(rateActions.apiResponseError(RateActionTypes.UPDATE_API_PROVIDER_STATUS, error));
        } else {
            yield put(rateActions.apiResponseValidationErrors(RateActionTypes.UPDATE_API_PROVIDER_STATUS, error));
        }
    }
}

function* getVariances({ payload: {symbol} }: RateData): SagaIterator {

    try {
        const param = {
            query:`query Variances($symbol: String!) {
                variances(symbol: $symbol) {
                    buy
                    sell
                }
            }`,
            variables: {
                "symbol": symbol,
            },
            operationName: "Variances"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, RateErrors.RESPONSE_200);

        const rDP = response.data;

        checkServerError(rDP);

        const variances = pluckResponse(rDP, "variances");

        yield put(rateActions.apiResponseSuccess(RateActionTypes.GET_VARIANCES, variances ?? {}));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(rateActions.apiResponseError(RateActionTypes.GET_VARIANCES, error));
        } else {
            yield put(rateActions.apiResponseValidationErrors(RateActionTypes.GET_VARIANCES, error));
        }
    }
}

function* updateVariances({ payload: {data} }: RateData): SagaIterator {

    try {
        const param = {
            query:`mutation UpdateRateVariance($symbol: String!, $buyVariance: String!, $sellVariance: String!) {
                updateRateVariance(symbol: $symbol, buyVariance: $buyVariance, sellVariance: $sellVariance)
            }`,
            variables: {
                ...data
            },
            operationName: "UpdateRateVariance"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, RateErrors.RESPONSE_200);

        const rDP = response.data;

        checkServerError(rDP);

        const updateRateVariance = pluckResponse(rDP, "updateRateVariance");

        yield put(rateActions.apiResponseSuccess(RateActionTypes.UPDATE_VARIANCES, updateRateVariance));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(rateActions.apiResponseError(RateActionTypes.UPDATE_VARIANCES, error));
        } else {
            yield put(rateActions.apiResponseValidationErrors(RateActionTypes.UPDATE_VARIANCES, error));
        }
    }
}
function* updateRatePrice({ payload: {data} }: RateData): SagaIterator {

    try {
        const param = {
            query:`mutation UpdateRatePrice($symbol: String!, $price: String!) {
                updateRatePrice(symbol: $symbol, price: $price)
            }`,
            variables: {
                ...data
            },
            operationName: "UpdateRatePrice"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, RateErrors.RESPONSE_200);

        const rDP = response.data;

        checkServerError(rDP);

        const updateRatePrice = pluckResponse(rDP, "updateRatePrice");

        yield put(rateActions.apiResponseSuccess(RateActionTypes.UPDATE_PRICE, updateRatePrice));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(rateActions.apiResponseError(RateActionTypes.UPDATE_PRICE, error));
        } else {
            yield put(rateActions.apiResponseValidationErrors(RateActionTypes.UPDATE_PRICE, error));
        }
    }
}

export function* watchGetRates(): any {
    yield takeEvery(RateActionTypes.GET_RATES, getRates);
}

export function* watchGetRate(): any {
    yield takeEvery(RateActionTypes.GET_RATE, getRate);
}

export function* watchGetApiProviderStatus(): any {
    yield takeEvery(RateActionTypes.GET_API_PROVIDER_STATUS, getApiProviderStatus);
}

export function* watchUpdateApiProviderStatus(): any {
    yield takeEvery(RateActionTypes.UPDATE_API_PROVIDER_STATUS, updateApiProviderStatus);
}

export function* watchGetVariances(): any {
    yield takeEvery(RateActionTypes.GET_VARIANCES, getVariances);
}

export function* watchUpdateVariances(): any {
    yield takeEvery(RateActionTypes.UPDATE_VARIANCES, updateVariances);
}

export function* watchUpdateRatePrice(): any {
    yield takeEvery(RateActionTypes.UPDATE_PRICE, updateRatePrice);
}

function* rateSaga() {
    yield all([
        fork(watchGetRates),
        fork(watchGetRate),
        fork(watchGetApiProviderStatus),
        fork(watchUpdateApiProviderStatus),
        fork(watchGetVariances),
        fork(watchUpdateVariances),
        fork(watchUpdateRatePrice),
    ]);
}

export default rateSaga;

import { IntlPaymentActionTypes } from './constants';

const INIT_STATE = {
    error: {},
    loading: {},
    orders: [],
    order: {},
    inserted: false,
    updated: false,
    review: {},
    canceled: false,
    trackIds: [],
    settingUpdated: false,
    setting: {},
};

type IntlPaymentActionType = {
    type:
        | IntlPaymentActionTypes.API_RESPONSE_SUCCESS
        | IntlPaymentActionTypes.API_RESPONSE_ERROR
        | IntlPaymentActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | IntlPaymentActionTypes.ORDERS
        | IntlPaymentActionTypes.ORDER
        | IntlPaymentActionTypes.CREATE
        | IntlPaymentActionTypes.UPDATE
        | IntlPaymentActionTypes.RESET
        | IntlPaymentActionTypes.REVIEW
        | IntlPaymentActionTypes.CANCEL
        | IntlPaymentActionTypes.UPDATE_SETTING
        | IntlPaymentActionTypes.GET_SETTING;

    payload: {
        actionType?: string;
        data?: any;
        error?: string;
        validationErrors?: [];

        key: string;
        value?: string;

        page?: string;
        trackId?: string;
    };
};

type State = {
    loading: {},
    error: {},
    orders: string[],
    order: any,
    trackIds: string[]
};

const OrderIntlPayment = (state: State = INIT_STATE, action: IntlPaymentActionType) => {
    switch (action.type) {
        case IntlPaymentActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case IntlPaymentActionTypes.ORDERS: {
                    return {
                        ...state,
                        orders: action.payload.data,
                        loading: {
                            orders: false
                        }
                    };
                }

                case IntlPaymentActionTypes.ORDER: {
                    return {
                        ...state,
                        order: action.payload.data,
                        loading: {
                            order: false
                        }
                    };
                }

                case IntlPaymentActionTypes.CREATE: {
                    return {
                        ...state,
                        inserted: action.payload.data,
                        loading: {
                            create: false
                        }
                    };
                }

                case IntlPaymentActionTypes.UPDATE: {
                    return {
                        ...state,
                        updated: true,
                        loading: {
                            update: false
                        }
                    };
                }

                case IntlPaymentActionTypes.REVIEW: {
                    return {
                        ...state,
                        review: action.payload.data,
                        loading: {
                            review: false
                        }
                    };
                }
                case IntlPaymentActionTypes.CANCEL: {
                    return {
                        ...state,
                        canceled: action.payload.data,
                        order: {
                            ...state.order,
                            status: "canceled"
                        },
                        loading: {
                            cancel: false
                        }
                    };
                }
                case IntlPaymentActionTypes.UPDATE_SETTING: {
                    return {
                        ...state,
                        settingUpdated: action.payload.data.result,
                        loading: {
                            updateSetting: false
                        },
                        trackIds: state.trackIds.filter(e => e !== action.payload.data.trackId)
                    };
                }
                case IntlPaymentActionTypes.GET_SETTING: {
                    return {
                        ...state,
                        setting: action.payload.data,
                        loading: {
                            getSetting: false
                        }
                    };
                }
                default:
                    return { ...state };
            }

        case IntlPaymentActionTypes.API_RESPONSE_ERROR:

            switch (action.payload.actionType) {

                case IntlPaymentActionTypes.ORDERS:
                {
                    return {
                        ...state,
                        error: {
                            "orders": action.payload.error
                        },
                        loading: {
                            "orders": false
                        },
                    };
                }
                case IntlPaymentActionTypes.ORDER:
                {
                    return {
                        ...state,
                        error: {
                            "order": action.payload.error
                        },
                        loading: {
                            "order": false
                        },
                    };
                }
                case IntlPaymentActionTypes.CREATE:
                {
                    return {
                        ...state,
                        error: {
                            "create": action.payload.error
                        },
                        loading: {
                            "create": false
                        },
                    };
                }
                case IntlPaymentActionTypes.UPDATE:
                {
                    return {
                        ...state,
                        error: {
                            "update": action.payload.error
                        },
                        loading: {
                            "update": false
                        },
                    };
                }
                case IntlPaymentActionTypes.REVIEW:
                {
                    return {
                        ...state,
                        error: {
                            "review": action.payload.error
                        },
                        loading: {
                            "review": false
                        },
                    };
                }
                case IntlPaymentActionTypes.CANCEL:
                {
                    return {
                        ...state,
                        error: {
                            "cancel": action.payload.error
                        },
                        loading: {
                            "cancel": false
                        },
                    };
                }
                case IntlPaymentActionTypes.UPDATE_SETTING:
                {
                    return {
                        ...state,
                        error: {
                            "updateSetting": action.payload.error
                        },
                        loading: {
                            "updateSetting": false
                        },
                        trackIds: state.trackIds.filter(e => e !== action.payload.trackId)
                    };
                }
                case IntlPaymentActionTypes.GET_SETTING:
                {
                    return {
                        ...state,
                        error: {
                            "getSetting": action.payload.error
                        },
                        loading: {
                            "getSetting": false
                        }
                    };
                }
                default:
                    return { ...state };
            }

        case IntlPaymentActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case IntlPaymentActionTypes.ORDERS:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "orders": "Invalid input error"
                        },
                        loading: {
                            "orders": false
                        },
                    };
                }
                case IntlPaymentActionTypes.ORDER:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "order": action.payload.validationErrors
                        },
                        loading: {
                            "order": false
                        },
                    };
                }
                case IntlPaymentActionTypes.CREATE:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "create": action.payload.validationErrors
                        },
                        loading: {
                            "create": false
                        },
                    };
                }
                case IntlPaymentActionTypes.UPDATE:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "update": action.payload.validationErrors
                        },
                        loading: {
                            "update": false
                        },
                    };
                }
                case IntlPaymentActionTypes.REVIEW:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "review": action.payload.validationErrors
                        },
                        loading: {
                            "review": false
                        },
                    };
                }
                case IntlPaymentActionTypes.CANCEL:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "review": action.payload.validationErrors
                        },
                        loading: {
                            "review": false
                        },
                    };
                }
                case IntlPaymentActionTypes.UPDATE_SETTING:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "updateSetting": action.payload.validationErrors
                        },
                        loading: {
                            "updateSetting": false
                        },
                    };
                }
                case IntlPaymentActionTypes.GET_SETTING:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "getSetting": action.payload.validationErrors
                        },
                        loading: {
                            "getSetting": false
                        },
                    };
                }
                default:
                    return { ...state };
            }

        case IntlPaymentActionTypes.ORDERS:
            return {
                ...state,
                loading: {
                    orders: true,
                },
                error: {
                    orders: "",
                },
            };
        case IntlPaymentActionTypes.ORDER:
            return {
                ...state,
                loading: {
                    order: true
                },
                error: {
                    order: ""
                },
            };

        case IntlPaymentActionTypes.CREATE:
            return {
                ...state,
                inserted: false,
                loading: {
                    create: true
                },
                error: {
                    create: ""
                },
            };
        case IntlPaymentActionTypes.UPDATE:
            return {
                ...state,
                updated: false,
                loading: {
                    update: true
                },
                error: {
                    update: ""
                },
            };

        case IntlPaymentActionTypes.RESET:
            return {
                ...state,
                loading: {},
                error: {},
                validationErrors: {},

                orders: [],
                order: {},
                inserted: false,
                updated: false,
                review: {},
                canceled: false,
                settingUpdated: false,
                setting: {}
            };

        case IntlPaymentActionTypes.REVIEW:
            return {
                ...state,
                loading: {
                    review: true
                },
                error: {
                    review: ""
                },
            };

        case IntlPaymentActionTypes.CANCEL:
            return {
                ...state,
                loading: {
                    cancel: true
                },
                error: {
                    cancel: ""
                },
            };

        case IntlPaymentActionTypes.UPDATE_SETTING:
            return {
                ...state,
                settingUpdated: false,
                loading: {
                    updateSetting: true
                },
                error: {
                    updateSetting: ""
                },
            };

        case IntlPaymentActionTypes.GET_SETTING:
            return {
                ...state,
                setting: {},
                loading: {
                    getSetting: true
                },
                error: {
                    getSetting: ""
                },
            };

        default:
            return { ...state };
    }
};

export default OrderIntlPayment;

import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { graphql } from 'helpers';
import {WalletActionTypes, WalletErrors} from './constants';
import {
    checkResponseError,
    checkServerError,
    pluckResponse,
} from "../../helpers/functions";
import {walletActions} from "./actions";

type WalletData = {
    payload: {
        id: string;
        name: string;
        permissions: string[];

        queryParams: {
            limit: string;
            page: string;
            orderBy: string;
            sort: string;

            walletName?: string;
        };

        data: any;

        accountId: string;
    };
    type: string;
};

function* getWallets({ payload: { queryParams } }: WalletData): SagaIterator {

    try {
        const param = {
            query:`query Wallets($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $accountId: String, $wallet: String) {
                wallets(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId, wallet: $wallet) {
                    data {
                        name,
                        slug,
                        balanceFloatNum,
                        holdFloatNum,
                        balanceHoldFloatNum,
                        holder {
                            id,
                            username,
                            email
                        }
                        meta {
                            sign
                            currency
                        }
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            },

            operationName: "Wallets"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, WalletErrors.RESPONSE_200);

        const rWallets = response.data;

        checkServerError(rWallets);

        const wallets = pluckResponse(rWallets, "wallets");

        const result = {
            wallets,
            queryParams
        }

        yield put(walletActions.apiResponseSuccess(WalletActionTypes.GET_WALLETS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(walletActions.apiResponseError(WalletActionTypes.GET_WALLETS, error));
        } else {
            yield put(walletActions.apiResponseValidationErrors(WalletActionTypes.GET_WALLETS, error));
        }
    }
}

function* searchWallet({ payload: { queryParams } }: WalletData): SagaIterator {
    try {
        const param = {
            query:`query Wallets(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $accountId: String,
                $wallet: String,
            ) {
                wallets(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort, 
                    accountId: $accountId,
                    wallet: $wallet,
                ) {
                    data {
                        name,
                        slug,
                        balanceFloatNum,
                        holdFloatNum,
                        balanceHoldFloatNum,
                        holder {
                            username,
                            email
                        }
                        meta {
                            sign
                            currency
                        }
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            },

            operationName: "Wallets"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, WalletErrors.RESPONSE_200);

        const rWallets = response.data;

        checkServerError(rWallets);

        const wallets = pluckResponse(rWallets, "wallets");

        const result = {
            wallets,
            queryParams
        }

        yield put(walletActions.apiResponseSuccess(WalletActionTypes.SEARCH_WALLET, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(walletActions.apiResponseError(WalletActionTypes.SEARCH_WALLET, error));
        } else {
            yield put(walletActions.apiResponseValidationErrors(WalletActionTypes.SEARCH_WALLET, error));
        }
    }
}

function* exportWallet({ payload: { queryParams } }: WalletData): SagaIterator {
    try {
        const param = {
            query:`query ExportWallets(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $accountId: String,
                $wallet: String,
            ) {
                exportWallets(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort, 
                    accountId: $accountId,
                    wallet: $wallet,
                )
            }`,

            variables: {
                ...queryParams
            },

            operationName: "ExportWallets"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, WalletErrors.RESPONSE_200);

        const rE = response.data;

        checkServerError(rE);

        const wallets = pluckResponse(rE, "exportWallets");

        yield put(walletActions.apiResponseSuccess(WalletActionTypes.EXPORT, wallets));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(walletActions.apiResponseError(WalletActionTypes.EXPORT, error));
        } else {
            yield put(walletActions.apiResponseValidationErrors(WalletActionTypes.EXPORT, error));
        }
    }
}

function* getWallet({ payload: {name} }: WalletData): SagaIterator {
    try {
        const param = {
            query:`query Wallet($name: String!) {
                wallet(name: $name) {
                    slug
                    balanceFloatNum
                    meta {
                        sign
                        currency
                    }
                }
            }`,

            variables: {
                name: name
            },

            operationName: "Wallet"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, WalletErrors.RESPONSE_200);

        const rWallet = response.data;

        checkServerError(rWallet);

        const wallet = pluckResponse(rWallet, "wallet");

        yield put(walletActions.apiResponseSuccess(WalletActionTypes.GET_WALLET, wallet));

    } catch (error: any) {

        if(typeof error === "string") {
            yield put(walletActions.apiResponseError(WalletActionTypes.GET_WALLET, error));
        } else {
            yield put(walletActions.apiResponseValidationErrors(WalletActionTypes.GET_WALLET, error));
        }
    }
}

function* getWalletByAccountID({ payload: {accountId} }: WalletData): SagaIterator {
    try {
        const param = {
            query:`query WalletByAccountID($accountId: String!) {
                walletByAccountID(accountId: $accountId) {
                    slug
                    balanceFloatNum
                }
            }`,

            variables: {
                accountId: accountId
            },

            operationName: "WalletByAccountID"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, WalletErrors.RESPONSE_200);

        const rWallet = response.data;

        checkServerError(rWallet);

        const wallet = pluckResponse(rWallet, "walletByAccountID");

        yield put(walletActions.apiResponseSuccess(WalletActionTypes.GET_WALLET_BY_ACCOUNT_ID, wallet));

    } catch (error: any) {

        if(typeof error === "string") {
            yield put(walletActions.apiResponseError(WalletActionTypes.GET_WALLET_BY_ACCOUNT_ID, error));
        } else {
            yield put(walletActions.apiResponseValidationErrors(WalletActionTypes.GET_WALLET_BY_ACCOUNT_ID, error));
        }
    }
}

export function* watchGetWallets() {
    yield takeEvery(WalletActionTypes.GET_WALLETS, getWallets);
}

export function* watchSearchWallet() {
    yield takeEvery(WalletActionTypes.SEARCH_WALLET, searchWallet);
}

export function* watchExportWallet() {
    yield takeEvery(WalletActionTypes.EXPORT, exportWallet);
}

export function* watchGetWallet() {
    yield takeEvery(WalletActionTypes.GET_WALLET, getWallet);
}

export function* watchGetWalletByAccountID() {
    yield takeEvery(WalletActionTypes.GET_WALLET_BY_ACCOUNT_ID, getWalletByAccountID);
}

function* walletsSaga() {
    yield all([
        fork(watchGetWallets),
        fork(watchSearchWallet),
        fork(watchExportWallet),
        fork(watchGetWallet),
        fork(watchGetWalletByAccountID)
    ]);
}

export default walletsSaga;

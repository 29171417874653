export enum OrderSettingActionTypes {
    API_RESPONSE_SUCCESS = '@@orderSetting/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@orderSetting/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@orderSetting/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@orderSetting/RESET',

    GET_OPTIONS = '@@orderSetting/GET_OPTIONS',
}

export enum OrderSettingErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.'
}




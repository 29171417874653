import { OrderSettingActionTypes } from './constants';

const INIT_STATE = {
    loading: {},
    error: {},
    validationErrors: {},
    option: {},
    trackIds: []
};

type OrderSettingData = {
    id: number;
    name: string;
};

type OrderSettingActionType = {
    type:
        | OrderSettingActionTypes.API_RESPONSE_SUCCESS
        | OrderSettingActionTypes.API_RESPONSE_ERROR
        | OrderSettingActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | OrderSettingActionTypes.RESET
        | OrderSettingActionTypes.GET_OPTIONS;

    payload: {
        actionType?: string;
        data?: OrderSettingData | any;
        error?: string;
        validationErrors?: [];

        key: string;
        value?: string;

        page?: string;

        trackId: string
    };
};

type State = {
    loading: {}
    trackIds: string[]
};

const OrderSetting = (state: State = INIT_STATE, action: OrderSettingActionType) => {
    switch (action.type) {
        case OrderSettingActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case OrderSettingActionTypes.GET_OPTIONS: {
                    return {
                        ...state,
                        option: action.payload.data,
                        loading: {
                            "getOption": false
                        },
                        error: {
                            "getOption": ""
                        }
                    };
                }
                default:
                    return { ...state };
            }

        case OrderSettingActionTypes.API_RESPONSE_ERROR:

            switch (action.payload.actionType) {

                case OrderSettingActionTypes.GET_OPTIONS:
                {
                    return {
                        ...state,
                        error: {
                            "getOption": action.payload.error
                        },
                        loading: {
                            "getOption": false
                        },
                    };
                }

                default:
                    return { ...state };
            }

        case OrderSettingActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case OrderSettingActionTypes.GET_OPTIONS: {
                    return {
                        ...state,
                        validationErrors: {
                            "getOption": action.payload.validationErrors
                        },
                        loading: {
                            "getOption": false
                        },
                    };
                }
                default:
                    return { ...state };
            }

        case OrderSettingActionTypes.GET_OPTIONS:

            return {
                ...state,
                loading: {
                    "getOption": true
                },
                error: {
                    "getOption": ""
                }
            };

        case OrderSettingActionTypes.RESET:

            return {
                ...state,
                error: {},
                loading: {},
                option: {}
            };

        default:
            return { ...state };
    }
};

export default OrderSetting;

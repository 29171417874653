import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {graphql} from 'helpers';
import {checkResponseError, checkServerError, pluckResponse} from "../../helpers/functions";
import { TokenActionTypes, TokenErrors } from './constants';
import { tokenActions } from './actions';

type TokenData = {
    payload: {
        id: number,

        data: any;

        queryParams: {
            limit: number;
            page: number;
        }
    };
    type: string;
};

function* getTokens({ payload: { queryParams } }: TokenData): SagaIterator {

    try {
        const param:any = {
            query:`query Tokens($page: Int!, $limit: Int!, $orderBy: String!, $sort: String!, $accountId: String) {
                tokens(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId) {
                    data {
                        id
                        user {
                            id
                            username
                        }
                        revoked
                        created_at
                        expires_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,
            variables: {
                ...queryParams
            },

            operationName: "Tokens"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, TokenErrors.RESPONSE_200);

        const rT = response.data;

        checkServerError(rT);

        const tokens = pluckResponse(rT, "tokens");

        const result = {
            tokens: {
                tokens,
                queryParams
            }
        }

        yield put(tokenActions.apiResponseSuccess(TokenActionTypes.GET_TOKENS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(tokenActions.apiResponseError(TokenActionTypes.GET_TOKENS, error));
        } else {
            yield put(tokenActions.apiResponseValidationErrors(TokenActionTypes.GET_TOKENS, error));
        }
    }
}

function* getToken({ payload: { id } }: TokenData): SagaIterator {

    try {
        const param:any = {
            query:`query Token($id: String!) {
                token(id: $id) {
                    id
                    user {
                        id
                        username
                    }
                    revoked
                    created_at
                    expires_at
                }
            }`,
            variables: {
                id
            },

            operationName: "Token"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, TokenErrors.RESPONSE_200);

        const rT = response.data;

        checkServerError(rT);

        const token = pluckResponse(rT, "token");

        yield put(tokenActions.apiResponseSuccess(TokenActionTypes.GET_TOKEN, token));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(tokenActions.apiResponseError(TokenActionTypes.GET_TOKEN, error));
        } else {
            yield put(tokenActions.apiResponseValidationErrors(TokenActionTypes.GET_TOKEN, error));
        }
    }
}

function* insertToken({ payload: {data} }: TokenData): SagaIterator {
    try {
        const param:any = {
            query: `mutation CreateToken($accountId: String){
                createToken(accountId: $accountId)
            }`,
            variables: {
                ...data
            },

            operationName: "CreateToken"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, TokenErrors.RESPONSE_200);

        const rIT = response.data;

        checkServerError(rIT);

        const createToken = pluckResponse(rIT, "createToken");

        yield put(tokenActions.apiResponseSuccess(TokenActionTypes.INSERT_TOKEN, createToken));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(tokenActions.apiResponseError(TokenActionTypes.INSERT_TOKEN, error));
        } else {
            yield put(tokenActions.apiResponseValidationErrors(TokenActionTypes.INSERT_TOKEN, error));
        }
    }
}

function* deleteToken({ payload: {id} }: TokenData): SagaIterator {
    try {
        const param:any = {
            query: `mutation DeleteToken($id: String!){
                deleteToken(id: $id)
            }`,
            variables: {
                id: id
            },

            operationName: "DeleteToken"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, TokenErrors.RESPONSE_200);

        const rDBC = response.data;

        checkServerError(rDBC);

        pluckResponse(rDBC, "deleteToken");

        yield put(tokenActions.apiResponseSuccess(TokenActionTypes.DELETE_TOKEN, id));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(tokenActions.apiResponseError(TokenActionTypes.DELETE_TOKEN, error));
        } else {
            yield put(tokenActions.apiResponseValidationErrors(TokenActionTypes.DELETE_TOKEN, error));
        }
    }
}

function* searchToken({ payload: { queryParams } }: TokenData): SagaIterator {
    try {
        const param = {
            query:`query Tokens(
                $page: Int!, 
                $limit: Int,
                $orderBy: String!,
                $sort: String!,
                $createdFrom: String,
                $createdTo: String,
                $accountId: String
            ) {
                tokens (
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    accountId: $accountId,
                ) {
                    data {
                        id
                        user {
                            id
                            username
                        }
                        revoked
                        created_at
                        expires_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            },

            operationName: "Tokens"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, TokenErrors.RESPONSE_200);

        const rT = response.data;

        checkServerError(rT);

        const tokens = pluckResponse(rT, "tokens");

        const result = {
            tokens,
            queryParams
        }

        yield put(tokenActions.apiResponseSuccess(TokenActionTypes.SEARCH_TOKEN, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(tokenActions.apiResponseError(TokenActionTypes.SEARCH_TOKEN, error));
        } else {
            yield put(tokenActions.apiResponseValidationErrors(TokenActionTypes.SEARCH_TOKEN, error));
        }
    }
}

export function* watchGetTokens(): any {
    yield takeEvery(TokenActionTypes.GET_TOKENS, getTokens);
}

export function* watchGetToken(): any {
    yield takeEvery(TokenActionTypes.GET_TOKEN, getToken);
}

export function* watchInsertToken(): any {
    yield takeEvery(TokenActionTypes.INSERT_TOKEN, insertToken);
}

export function* watchDeleteToken(): any {
    yield takeEvery(TokenActionTypes.DELETE_TOKEN, deleteToken);
}

export function* watchSearchToken(): any {
    yield takeEvery(TokenActionTypes.SEARCH_TOKEN, searchToken);
}

function* TokenSaga() {
    yield all([
        fork(watchGetTokens),
        fork(watchGetToken),
        fork(watchInsertToken),
        fork(watchDeleteToken),
        fork(watchSearchToken)
    ]);
}

export default TokenSaga;

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {ProviderActionTypes, ProviderErrors} from "./constants";
import {providerActions} from "./actions";
import {graphql} from "../../helpers";
import {SagaIterator} from "@redux-saga/core";
import {checkResponseError, checkServerError, pluckResponse} from "../../helpers/functions";

type ProviderData = {
    payload: {
        id: string;

        limit: string;
        page: string;

        queryParams: {
            limit: string;
            page: string;
            orderBy: string;
            sort: string;
        };

        data: any;
    };
    type: string;
};

function* providers({ payload: { queryParams } }: ProviderData): SagaIterator {
    try {
        const param = {
            query:`query Providers {
                providers {
                    id
                    name
                    slug
                    status
                    description
                }
            }`,

            variables: {
                ...queryParams
            },
            operationName: "Providers"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ProviderErrors.RESPONSE_200);

        const rp = response.data;

        checkServerError(rp);

        const providers = pluckResponse(rp, "providers");

        yield put(providerActions.apiResponseSuccess(ProviderActionTypes.PROVIDERS, providers));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(providerActions.apiResponseError(ProviderActionTypes.PROVIDERS, error));
        } else {
            yield put(providerActions.apiResponseValidationErrors(ProviderActionTypes.PROVIDERS, error));
        }
    }
}

function* provider({ payload: {data} }: ProviderData): SagaIterator {
    try {
        const param = {
            query:`query Provider($id: Int!) {
                provider(id: $id) {
                    id
                    name
                    slug
                    meta
                    status
                    description
                }
            }`,

            variables: {
                ...data
            },
            operationName: "Provider"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ProviderErrors.RESPONSE_200);

        const rp = response.data;

        checkServerError(rp);

        const provider = pluckResponse(rp, "provider");

        yield put(providerActions.apiResponseSuccess(ProviderActionTypes.PROVIDER, provider));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(providerActions.apiResponseError(ProviderActionTypes.PROVIDER, error));
        } else {
            yield put(providerActions.apiResponseValidationErrors(ProviderActionTypes.PROVIDER, error));
        }
    }
}

function* update({ payload: {data} }: ProviderData): SagaIterator {
    try {
        const param = {

            query:`mutation UpdateProvider(
                $id: Int!,
                $name: String!,
                $status: String!,
                $meta: String,
                $description: String,
            ) {
                updateProvider(
                    id: $id,
                    name: $name,
                    status: $status,
                    meta: $meta,
                    description: $description,
                )
            }`,

            variables: { ...data },
            operationName: "UpdateProvider"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ProviderErrors.RESPONSE_200);

        const rup = response.data;

        checkServerError(rup);

        pluckResponse(rup, "updateProvider");

        yield put(providerActions.apiResponseSuccess(ProviderActionTypes.UPDATE, data));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(providerActions.apiResponseError(ProviderActionTypes.UPDATE, error));
        } else {
            yield put(providerActions.apiResponseValidationErrors(ProviderActionTypes.UPDATE, error));
        }
    }
}

function* changeStatus({ payload: {data} }: ProviderData): SagaIterator {
    try {
        const param = {

            query:`mutation ChangeProviderStatus(
                $id: Int!,
                $status: String!,
            ) {
                changeProviderStatus(
                    id: $id,
                    status: $status,
                )
            }`,

            variables: { ...data },
            operationName: "ChangeProviderStatus"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ProviderErrors.RESPONSE_200);

        const rep = response.data;

        checkServerError(rep);

        pluckResponse(rep, "changeProviderStatus");

        yield put(providerActions.apiResponseSuccess(ProviderActionTypes.CHANGE_STATUS, {
            id: data.id,
            status: data.status,
        }));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(providerActions.apiResponseError(ProviderActionTypes.CHANGE_STATUS, error));
        } else {
            yield put(providerActions.apiResponseValidationErrors(ProviderActionTypes.CHANGE_STATUS, error));
        }
    }
}

export function* watchProviders() {
    yield takeEvery(ProviderActionTypes.PROVIDERS, providers);
}

export function* watchProvider() {
    yield takeEvery(ProviderActionTypes.PROVIDER, provider);
}

export function* watchUpdate() {
    yield takeEvery(ProviderActionTypes.UPDATE, update);
}

export function* watchChangeStatus() {
    yield takeEvery(ProviderActionTypes.CHANGE_STATUS, changeStatus);
}

function* providerSaga() {
    yield all([
        fork(watchProviders),
        fork(watchProvider),
        fork(watchUpdate),
        fork(watchChangeStatus),
    ]);
}

export default providerSaga;

import { all, fork, put, takeEvery, call, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { graphql } from 'helpers';
import {WithdrawActionTypes, WithdrawErrors} from './constants';
import {
    checkResponseError,
    checkServerError,
    pluckResponse,
} from "../../helpers/functions";
import {withdrawActions} from "./actions";

type WithdrawData = {
    payload: {
        id: string;
        name: string;
        permissions: string[];

        queryParams: {
            limit: string;
            page: string;
            orderBy: string;
            sort: string;

            withdrawName?: string;
        };

        data: any;
        trackId: string;
    };
    type: string;
};

function* getWithdraws({ payload: { queryParams } }: WithdrawData): SagaIterator {

    try {
        const param = {
            query:`query Withdraws($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $accountId: String, $wallet: String) {
                withdraws(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId, wallet: $wallet) {
                    data {
                        wallet {
                            uuid
                            name
                            holder {
                                id
                                email
                                username
                                display_name
                            }
                            meta {
                                sign
                                currency
                            }
                        }
                        type
                        amountFloat
                        confirmed
                        meta {
                            description
                        }
                        balanceFloat
                        iban
                        bank_name
                        card_number
                        bank_account_number
                        commission_float
                        name
                        transaction_id
                        thread_id
                        status
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            },

            operationName: "Withdraws"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, WithdrawErrors.RESPONSE_200);

        const rWithdraws = response.data;

        checkServerError(rWithdraws);

        const withdraws = pluckResponse(rWithdraws, "withdraws");

        const result = {
            withdraws,
            queryParams
        }

        yield put(withdrawActions.apiResponseSuccess(WithdrawActionTypes.GET_WITHDRAWS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(withdrawActions.apiResponseError(WithdrawActionTypes.GET_WITHDRAWS, error));
        } else {
            yield put(withdrawActions.apiResponseValidationErrors(WithdrawActionTypes.GET_WITHDRAWS, error));
        }
    }
}

function* getWithdrawRequests({ payload: { queryParams } }: WithdrawData): SagaIterator {

    try {
        const param = {
            query:`query WithdrawRequests($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $accountId: String, $wallet: String) {
                withdrawRequests(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId, wallet: $wallet) {
                    data {
                        wallet {
                            uuid
                            name
                            holder {
                                id
                                email
                                username
                                display_name
                            }
                            meta {
                                sign
                                currency
                            }
                        }
                        type
                        amountFloat
                        confirmed
                        meta {
                            description
                        }
                        balanceFloat
                        iban
                        bank_name
                        card_number
                        bank_account_number
                        
                        final_amount_float
                        commission_float
                        name
                        transaction_id
                        thread_id
                        status
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            },

            operationName: "WithdrawRequests"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, WithdrawErrors.RESPONSE_200);

        const rWithdraws = response.data;

        checkServerError(rWithdraws);

        const withdraws = pluckResponse(rWithdraws, "withdrawRequests");

        const result = {
            withdraws,
            queryParams
        }

        yield put(withdrawActions.apiResponseSuccess(WithdrawActionTypes.GET_WITHDRAWS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(withdrawActions.apiResponseError(WithdrawActionTypes.GET_WITHDRAWS, error));
        } else {
            yield put(withdrawActions.apiResponseValidationErrors(WithdrawActionTypes.GET_WITHDRAWS, error));
        }
    }
}

function* getWithdraw({ payload: { id } }: WithdrawData): SagaIterator {

    try {
        const param = {
            query:`query Withdraw($id: String!) {
                withdraw(id: $id) {
                    wallet {
                        uuid
                        name
                        holder {
                            id
                            email
                            username
                            display_name
                        }
                        meta {
                            sign
                            currency
                        }
                    }
                    uuid
                    type
                    amount
                    amountFloat
                    confirmed
                    meta {
                        description
                    }
                    method
                    fee_float
                    discount_float
                    final_amount_float
                    bank_charge_fee_float
                    commission_float
                    
                    bank_name
                    iban
                    card_number
                    bank_account_number
                    bank_account_id
                    track_id
                    date
                    
                    name
                    transaction_id
                    thread_id
                    status
                    created_at
                }
            }`,

            variables: {
                id: id
            },

            operationName: "Withdraw"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, WithdrawErrors.RESPONSE_200);

        const rT = response.data;

        checkServerError(rT);

        const withdraw = pluckResponse(rT, "withdraw");

        yield put(withdrawActions.apiResponseSuccess(WithdrawActionTypes.GET_WITHDRAW, withdraw));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(withdrawActions.apiResponseError(WithdrawActionTypes.GET_WITHDRAW, error));
        } else {
            yield put(withdrawActions.apiResponseValidationErrors(WithdrawActionTypes.GET_WITHDRAW, error));
        }
    }
}

function* CheckWithdraw({ payload: {data} }: WithdrawData): SagaIterator {
    try {
        const param = {
            query:`mutation CheckWithdraw(
                $iban: String!,
                $amount: String!
            ) {
                checkWithdraw(
                    iban: $iban,
                    amount: $amount
                )
            }`,

            variables: { ...data },

            operationName: "CheckWithdraw"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, WithdrawErrors.RESPONSE_200);

        const rCW = response.data;

        checkServerError(rCW);

        const checkWithdraw = pluckResponse(rCW, "checkWithdraw");

        yield put(withdrawActions.apiResponseSuccess(WithdrawActionTypes.CHECK_WITHDRAW, checkWithdraw));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(withdrawActions.apiResponseError(WithdrawActionTypes.CHECK_WITHDRAW, error));
        } else {
            yield put(withdrawActions.apiResponseValidationErrors(WithdrawActionTypes.CHECK_WITHDRAW, error));
        }
    }
}

function* insertWithdraw({ payload: {data} }: WithdrawData): SagaIterator {
    try {
        const param = {
            query:`mutation CreateIRRWithdraw(
                $iban: String!,
                $amount: String!,
                $twoFaOtp: String,
                $otp: String
            ) {
                createIRRWithdraw(
                    iban: $iban,
                    amount: $amount,
                    twoFaOtp: $twoFaOtp,
                    otp: $otp
                )
            }`,

            variables: { ...data },

            operationName: "CreateIRRWithdraw"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, WithdrawErrors.RESPONSE_200);

        const rCD = response.data;

        checkServerError(rCD);

        const createWithdraw = pluckResponse(rCD, "createIRRWithdraw");

        yield put(withdrawActions.apiResponseSuccess(WithdrawActionTypes.INSERT_WITHDRAW, createWithdraw));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(withdrawActions.apiResponseError(WithdrawActionTypes.INSERT_WITHDRAW, error));
        } else {
            yield put(withdrawActions.apiResponseValidationErrors(WithdrawActionTypes.INSERT_WITHDRAW, error));
        }
    }
}

function* verifyWithdraw({ payload: {data} }: WithdrawData): SagaIterator {
    try {
        const param = {
            query:`mutation ApproveIRRWithdraw(
                $transactionId: String!,
                $status: String!,
                $trackId: String,
                $description: String,
            ) {
                approveIRRWithdraw(
                    transactionId: $transactionId
                    trackId: $trackId
                    status: $status
                    description: $description
                )
            }`,

            variables: { ...data },

            operationName: "ApproveIRRWithdraw"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, WithdrawErrors.RESPONSE_200);

        const rVD = response.data;

        checkServerError(rVD);

        const verifyWithdraw = pluckResponse(rVD, "approveIRRWithdraw");

        yield put(withdrawActions.apiResponseSuccess(WithdrawActionTypes.VERIFY_WITHDRAW, verifyWithdraw));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(withdrawActions.apiResponseError(WithdrawActionTypes.VERIFY_WITHDRAW, error));
        } else {
            yield put(withdrawActions.apiResponseValidationErrors(WithdrawActionTypes.VERIFY_WITHDRAW, error));
        }
    }
}

function* updateWithdraw({ payload: {
    id,
    name,
    permissions,
} }: WithdrawData): SagaIterator {
    try {
        const param = {
            query:`mutation UpdateWithdraw(
                $id: Int!,
                $name: String!,
                $permissions: [String!]
            ) {
                updateWithdraw(
                    id: $id,
                    name: $name,
                    permissions: $permissions
                ) {
                    name
                }
            }`,
            variables: {
                "id": id,
                "name": name,
                "permissions": permissions
            },

            operationName: "UpdateWithdraw"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, WithdrawErrors.RESPONSE_200);

        const rUpdateWithdraw = response.data;

        checkServerError(rUpdateWithdraw);

        const updateWithdraw = pluckResponse(rUpdateWithdraw, "updateWithdraw");

        yield put(withdrawActions.apiResponseSuccess(WithdrawActionTypes.UPDATE_WITHDRAW, updateWithdraw));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(withdrawActions.apiResponseError(WithdrawActionTypes.UPDATE_WITHDRAW, error));
        } else {
            yield put(withdrawActions.apiResponseValidationErrors(WithdrawActionTypes.UPDATE_WITHDRAW, error));
        }
    }
}

function* searchWithdraw({ payload: { queryParams } }: WithdrawData): SagaIterator {
    try {
        const param = {
            query:`query WithdrawRequests(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $wallet: String, 
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $transactionId: String,
                $threadId: String,
                $accountId: String,
                $transactionNames: [String],
                $transactionType: String
            ) {
                withdrawRequests(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort, 
                    wallet: $wallet,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    transactionId: $transactionId,
                    threadId: $threadId,
                    accountId: $accountId,
                    transactionNames: $transactionNames,
                    transactionType: $transactionType
                ) {
                    data {
                        wallet {
                            uuid
                            name
                            holder {
                                id
                                email
                                username
                                display_name
                            }
                            meta {
                                sign
                                currency
                            }
                        }
                        type
                        amountFloat
                        confirmed
                        meta {
                            description
                        }
                        
                        balanceFloat
                        iban
                        bank_name
                        card_number
                        bank_account_number
                        commission_float
                        
                        name
                        transaction_id
                        thread_id
                        status
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            },

            operationName: "WithdrawRequests"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, WithdrawErrors.RESPONSE_200);

        const rWithdraws = response.data;

        checkServerError(rWithdraws);

        const withdraws = pluckResponse(rWithdraws, "withdrawRequests");

        const result = {
            withdraws,
            queryParams
        }

        yield put(withdrawActions.apiResponseSuccess(WithdrawActionTypes.SEARCH_WITHDRAW, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(withdrawActions.apiResponseError(WithdrawActionTypes.SEARCH_WITHDRAW, error));
        } else {
            yield put(withdrawActions.apiResponseValidationErrors(WithdrawActionTypes.SEARCH_WITHDRAW, error));
        }
    }
}

function* manualWithdraw({ payload: {data} }: WithdrawData): SagaIterator {
    try {
        const param = {
            query:`mutation ManualWithdraw(
                $accountId: String!,
                $wallet: String!,
                $amount: String!,
                $bankCharges: String!,
                $fee: String!,
                $status: String!,
                $method: String!,
                $description: String,
                
                $bankName: String
                $cardNumber: String
                $iban: String
                $bankAccountNumber: String
            ) {
                manualWithdraw(
                    accountId: $accountId
                    wallet: $wallet
                    amount: $amount
                    bankCharges: $bankCharges
                    fee: $fee
                    status: $status
                    method: $method
                    description: $description
                    bankName: $bankName
                    cardNumber: $cardNumber
                    iban: $iban
                    bankAccountNumber: $bankAccountNumber
                )
            }`,

            variables: { ...data },

            operationName: "ManualWithdraw"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, WithdrawErrors.RESPONSE_200);

        const rMW = response.data;

        checkServerError(rMW);

        const manualWithdraw = pluckResponse(rMW, "manualWithdraw");

        yield put(withdrawActions.apiResponseSuccess(WithdrawActionTypes.MANUAL_WITHDRAW, manualWithdraw));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(withdrawActions.apiResponseError(WithdrawActionTypes.MANUAL_WITHDRAW, error));
        } else {
            yield put(withdrawActions.apiResponseValidationErrors(WithdrawActionTypes.MANUAL_WITHDRAW, error));
        }
    }
}

function* previewWithdrawDetail({ payload: { data } }: WithdrawData): SagaIterator {
    try {
        const param = {
            query:`query PreviewWithdrawDetail($wallet: String!, $amount: String!, $bankCharges: String, $fee: String) {
                previewWithdrawDetail(wallet: $wallet, amount: $amount, bankCharges: $bankCharges, fee: $fee) {
                    bankCharge
                    subTotal
                    fee
                    total
                }
            }`,

            variables: {
                ...data
            },

            operationName: "PreviewWithdrawDetail"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, WithdrawErrors.RESPONSE_200);

        const rWD = response.data;

        checkServerError(rWD);

        const previewExchangeDetail = pluckResponse(rWD, "previewWithdrawDetail");

        yield put(withdrawActions.apiResponseSuccess(WithdrawActionTypes.PREVIEW_WITHDRAW_DETAIL, previewExchangeDetail));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(withdrawActions.apiResponseError(WithdrawActionTypes.PREVIEW_WITHDRAW_DETAIL, error));
        } else {
            yield put(withdrawActions.apiResponseValidationErrors(WithdrawActionTypes.PREVIEW_WITHDRAW_DETAIL, error));
        }
    }
}

function* exportTransactions({ payload: { queryParams } }: WithdrawData): SagaIterator {
    try {
        const param = {
            query:`query ExportWithdrawRequests(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $wallet: String, 
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $transactionId: String,
                $threadId: String,
                $accountId: String,
                $transactionNames: [String],
                $transactionType: String
            ) {
                exportWithdrawRequests(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort, 
                    wallet: $wallet,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    transactionId: $transactionId,
                    threadId: $threadId,
                    accountId: $accountId,
                    transactionNames: $transactionNames,
                    transactionType: $transactionType
                )
            }`,

            variables: {
                ...queryParams
            },

            operationName: "ExportWithdrawRequests"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, WithdrawErrors.RESPONSE_200);

        const rTransactions = response.data;

        checkServerError(rTransactions);

        const transactions = pluckResponse(rTransactions, "exportWithdrawRequests");

        yield put(withdrawActions.apiResponseSuccess(WithdrawActionTypes.EXPORT, transactions));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(withdrawActions.apiResponseError(WithdrawActionTypes.EXPORT, error));
        } else {
            yield put(withdrawActions.apiResponseValidationErrors(WithdrawActionTypes.EXPORT, error));
        }
    }
}

function* totalWithdraws({ payload: { queryParams } }: WithdrawData): SagaIterator {
    try {
        const param = {
            query:`query TotalWithdraws(
                $name: [String!]
                $status: String
            ) {
                totalWithdraws(
                    name: $name
                    status: $status
                ) {
                    total
                    count
                }
            }`,

            variables: {
                ...queryParams
            },

            operationName: "TotalWithdraws"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, WithdrawErrors.RESPONSE_200);

        const rTransactions = response.data;

        checkServerError(rTransactions);

        const totalWithdraws = pluckResponse(rTransactions, "totalWithdraws");

        yield put(withdrawActions.apiResponseSuccess(WithdrawActionTypes.TOTAL_WITHDRAW, totalWithdraws));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(withdrawActions.apiResponseError(WithdrawActionTypes.TOTAL_WITHDRAW, error));
        } else {
            yield put(withdrawActions.apiResponseValidationErrors(WithdrawActionTypes.TOTAL_WITHDRAW, error));
        }
    }
}

export function* watchGetWithdraws() {
    yield takeEvery(WithdrawActionTypes.GET_WITHDRAWS, getWithdraws);
}

export function* watchGetWithdrawRequests() {
    yield takeEvery(WithdrawActionTypes.GET_WITHDRAW_REQUESTS, getWithdrawRequests);
}

export function* watchGetWithdraw() {
    yield takeEvery(WithdrawActionTypes.GET_WITHDRAW, getWithdraw);
}

export function* watchCheckWithdraw() {
    yield takeEvery(WithdrawActionTypes.CHECK_WITHDRAW, CheckWithdraw);
}

export function* watchInsertWithdraw() {
    yield takeEvery(WithdrawActionTypes.INSERT_WITHDRAW, insertWithdraw);
}

export function* watchVerifyWithdraw() {
    yield takeEvery(WithdrawActionTypes.VERIFY_WITHDRAW, verifyWithdraw);
}

export function* watchUpdateWithdraw() {
    yield takeEvery(WithdrawActionTypes.UPDATE_WITHDRAW, updateWithdraw);
}

export function* watchSearchWithdraw() {
    yield takeEvery(WithdrawActionTypes.SEARCH_WITHDRAW, searchWithdraw);
}

export function* watchManualWithdraw() {
    yield takeEvery(WithdrawActionTypes.MANUAL_WITHDRAW, manualWithdraw);
}

export function* watchPreviewWithdrawDetail() {
    yield takeLatest(WithdrawActionTypes.PREVIEW_WITHDRAW_DETAIL, previewWithdrawDetail);
}

export function* watchExportTransactions() {
    yield takeEvery(WithdrawActionTypes.EXPORT, exportTransactions);
}

export function* watchTotalWithdraws() {
    yield takeEvery(WithdrawActionTypes.TOTAL_WITHDRAW, totalWithdraws);
}


function* withdrawsSaga() {
    yield all([
        fork(watchGetWithdraws),
        fork(watchGetWithdrawRequests),
        fork(watchGetWithdraw),
        fork(watchCheckWithdraw),
        fork(watchInsertWithdraw),
        fork(watchVerifyWithdraw),
        fork(watchUpdateWithdraw),
        fork(watchSearchWithdraw),
        fork(watchManualWithdraw),
        fork(watchPreviewWithdrawDetail),
        fork(watchExportTransactions),
        fork(watchTotalWithdraws),
    ]);
}

export default withdrawsSaga;

import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {graphql} from '../../../helpers';
import {ApiServiceActionTypes, ApiServiceErrors} from './constants';
import {checkResponseError, checkServerError, pluckResponse} from "../../../helpers/functions";
import {apiServiceActions} from "./actions";

function* getApiToken(): SagaIterator {
    try {
        const param:any = {
            query:`query UserApiToken {
                userApiToken
            }`,
            operationName: "UserApiToken"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ApiServiceErrors.RESPONSE_200);

        const rUAT = response.data;

        checkServerError(rUAT);

        const userApiToken = pluckResponse(rUAT, "userApiToken");

        yield put(apiServiceActions.apiResponseSuccess(ApiServiceActionTypes.GET_API_TOKEN, userApiToken));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(apiServiceActions.apiResponseError(ApiServiceActionTypes.GET_API_TOKEN, error));
        } else {
            yield put(apiServiceActions.apiResponseValidationErrors(ApiServiceActionTypes.GET_API_TOKEN, error));
        }
    }
}

function* createApiToken(): SagaIterator {
    try {
        const param = {
            query:`mutation CreateApiToken {
                createApiToken
            }`,
            operationName: "CreateApiToken"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ApiServiceErrors.RESPONSE_200);

        const cAT = response.data;

        checkServerError(cAT);

        const createApiToken = pluckResponse(cAT, "createApiToken");

        yield put(apiServiceActions.apiResponseSuccess(ApiServiceActionTypes.CREATE_API_TOKEN, createApiToken));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(apiServiceActions.apiResponseError(ApiServiceActionTypes.CREATE_API_TOKEN, error));
        } else {
            yield put(apiServiceActions.apiResponseValidationErrors(ApiServiceActionTypes.CREATE_API_TOKEN, error));
        }
    }
}

export function* watchCreateApiToken(): any {
    yield takeEvery(ApiServiceActionTypes.CREATE_API_TOKEN, createApiToken);
}

export function* watchGetApiToken(): any {
    yield takeEvery(ApiServiceActionTypes.GET_API_TOKEN, getApiToken);
}

function* apiServiceSaga() {
    yield all([
        fork(watchCreateApiToken),
        fork(watchGetApiToken),
    ]);
}

export default apiServiceSaga;

import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {graphql} from 'helpers';
import {checkResponseError, checkServerError, formatBytes, pluckResponse} from "../../helpers/functions";
import {manageVerificationActions} from "./actions";
import { ManageVerificationActionTypes, ManageVerificationErrors } from './constants';

type PersonalDetailData = {
    payload: {
        id: number,

        data: any;

        queryParams: {
            limit: number;
            page: number;

        }

        token: string;

        file: {fileId: string} & File;

        fileId: string;
        uuid: string;
    };
    type: string;
};

function formattingFile(file: any) {
    file.formattedSize = formatBytes(file.formattedSize);
    file.uploaded = true;
    file.progress = 0;
    file.fileId = "f" + (Math.random() + 1).toString(36).substring(2);
    return file;
}

function* getVerificationItems({ payload: { id } }: PersonalDetailData): SagaIterator {
    try {
        const param:any = {
            query:`query User($id: String!) {
                user(id: $id) {
                    id
                    mobile
                    mobile_ownership_status
                    email
                    customerProfile {
                        id
                        verification_method
                        status
                        first_name
                        last_name
                    }
                    idNcFront {
                        id
                        status
                        media {
                             url
                        }
                    }
                    idNcBack {
                        id
                        status
                        media {
                             url
                        }
                    }
                    idPassport {
                        id
                        status
                        media {
                             url
                        }
                    }
                    idDrivingLicence {
                        id
                        status
                        media {
                             url
                        }
                    }
                }
            }`,
            variables: {
                "id": id,
            },
            operationName: "User"
        };

        // f.preview blob:http://localhost:3000/6f50e966-594e-4204-9f2c-65020cc998e0
        // f.name Screenshot 2023-10-25 114422.png
        // f.type image/png
        // f.formattedSize 13.95 KB

        const response: any = yield call(graphql, param, 'auth');
        checkResponseError(response, ManageVerificationErrors.RESPONSE_200);

        const rU: any = response.data;

        checkServerError(rU);

        const user = pluckResponse(rU, "user");



        yield put(manageVerificationActions.apiResponseSuccess(ManageVerificationActionTypes.GET_VERIFICATION_ITEMS, user));


    } catch (error: any) {

        if(typeof error === "string") {
            yield put(manageVerificationActions.apiResponseError(ManageVerificationActionTypes.GET_VERIFICATION_ITEMS, error));
        } else {
            yield put(manageVerificationActions.apiResponseValidationErrors(ManageVerificationActionTypes.GET_VERIFICATION_ITEMS, error));
        }
    }
}


export function* watchGetVerificationItems() {
    yield takeEvery(ManageVerificationActionTypes.GET_VERIFICATION_ITEMS, getVerificationItems);
}

function* manageVerificationSaga() {
    yield all([
        fork(watchGetVerificationItems),
    ]);
}

export default manageVerificationSaga;

import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {graphql} from 'helpers';
import {checkResponseError, checkServerError, pluckResponse} from "../../helpers/functions";
import { IBANActionTypes, IBANErrors } from './constants';
import { IBANActions } from './actions';

type IBANData = {
    payload: {
        id: number,

        data: any;

        queryParams: {
            limit: number;
            page: number;
        }
    };
    type: string;
};

function* getIBANs({ payload: { queryParams } }: IBANData): SagaIterator {

    try {
        const param:any = {
            query:`query IBANs($page: Int!, $limit: Int!, $orderBy: String!, $sort: String!, $status: String!, $accountId: String) {
                IBANs(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, status: $status, accountId: $accountId) {
                    data {
                        id
                        user {
                            id
                            username
                            email
                            display_name
                        }
                        bank_name
                        number
                        status
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,
            variables: {
                ...queryParams
            },
            operationName: "IBANs"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IBANErrors.RESPONSE_200);

        const rIBANs = response.data;

        checkServerError(rIBANs);

        const IBANs = pluckResponse(rIBANs, "IBANs");

        const result = {
            IBANs: {
                IBANs,
                queryParams
            }
        }

        yield put(IBANActions.apiResponseSuccess(IBANActionTypes.GET_IBANS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(IBANActions.apiResponseError(IBANActionTypes.GET_IBANS, error));
        } else {
            yield put(IBANActions.apiResponseValidationErrors(IBANActionTypes.GET_IBANS, error));
        }
    }
}

function* getIBAN({ payload: { id } }: IBANData): SagaIterator {

    try {
        const param:any = {
            query:`query IBAN($id: String!) {
                IBAN(id: $id) {
                    user {
                        display_name
                    }
                    bank_name,
                    number,
                    status,
                }
            }`,
            variables: {
                id
            },
            operationName: "IBAN"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IBANErrors.RESPONSE_200);

        const rIBAN = response.data;

        checkServerError(rIBAN);

        const IBAN = pluckResponse(rIBAN, "IBAN");

        yield put(IBANActions.apiResponseSuccess(IBANActionTypes.GET_IBAN, IBAN));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(IBANActions.apiResponseError(IBANActionTypes.GET_IBAN, error));
        } else {
            yield put(IBANActions.apiResponseValidationErrors(IBANActionTypes.GET_IBAN, error));
        }
    }
}

function* insertIBAN({ payload: {data} }: IBANData): SagaIterator {
    try {
        const param:any = {
            query: `mutation CreateIBAN($iban: String!){
                createIBAN(iban: $iban)
            }`,
            variables: {
                ...data
            },
            operationName: "CreateIBAN"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IBANErrors.RESPONSE_200);

        const rInsertIBAN = response.data;

        checkServerError(rInsertIBAN);

        pluckResponse(rInsertIBAN, "createIBAN");

        yield put(IBANActions.apiResponseSuccess(IBANActionTypes.INSERT_IBAN, true));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(IBANActions.apiResponseError(IBANActionTypes.INSERT_IBAN, error));
        } else {
            yield put(IBANActions.apiResponseValidationErrors(IBANActionTypes.INSERT_IBAN, error));
        }
    }
}

function* deleteIBAN({ payload: {id} }: IBANData): SagaIterator {
    try {
        const param:any = {
            query: `mutation DeleteIBAN($id: String!){
                deleteIBAN(id: $id)
            }`,
            variables: {
                id: id
            },
            operationName: "DeleteIBAN"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IBANErrors.RESPONSE_200);

        const rDBC = response.data;

        checkServerError(rDBC);

        pluckResponse(rDBC, "deleteIBAN");

        yield put(IBANActions.apiResponseSuccess(IBANActionTypes.DELETE_IBAN, id));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(IBANActions.apiResponseError(IBANActionTypes.DELETE_IBAN, error));
        } else {
            yield put(IBANActions.apiResponseValidationErrors(IBANActionTypes.DELETE_IBAN, error));
        }
    }
}

export function* watchGetIBANs(): any {
    yield takeEvery(IBANActionTypes.GET_IBANS, getIBANs);
}

export function* watchGetIBAN(): any {
    yield takeEvery(IBANActionTypes.GET_IBAN, getIBAN);
}

export function* watchInsertIBAN(): any {
    yield takeEvery(IBANActionTypes.INSERT_IBAN, insertIBAN);
}

export function* watchDeleteIBAN(): any {
    yield takeEvery(IBANActionTypes.DELETE_IBAN, deleteIBAN);
}

function* IBANSaga() {
    yield all([
        fork(watchGetIBANs),
        fork(watchGetIBAN),
        fork(watchInsertIBAN),
        fork(watchDeleteIBAN),
    ]);
}

export default IBANSaga;

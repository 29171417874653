import { all, fork, put, takeEvery, call, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { graphql } from 'helpers';
import {ExchangeActionTypes, ExchangeErrors} from './constants';
import {
    checkResponseError,
    checkServerError,
    pluckResponse,
} from "../../helpers/functions";
import {exchangeActions} from "./actions";
import { APICore } from '../../helpers/api/apiCore';

type ExchangeData = {
    payload: {
        queryParams: {
            limit: string;
            page: string;
            orderBy: string;
            sort: string;
        };

        data: any;
        id: string;
    };
    type: string;
};

function* getExchanges({ payload: { queryParams } }: ExchangeData): SagaIterator {
    try {
        const param = {
            query:`query Exchanges($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $accountId: String) {
                exchanges(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId) {
                    data {
                        id
                        from {
                            name
                            balanceFloatNum
                            meta {
                                sign
                                currency
                            }
                            holder {
                                id
                                username
                                email
                            }
                        }
                        to {
                            name
                            balanceFloatNum
                            meta {
                                sign
                                currency
                            }
                        }
                        withdraw {
                            amountFloat
                            meta {
                                description
                            }
                        }
                        deposit {
                            amountFloat
                            meta {
                                description
                            }
                            rate
                            name
                        }
                        fee
                        feeFloat
                        status
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            },
            operationName: "Exchanges"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ExchangeErrors.RESPONSE_200);

        const rExchanges = response.data;

        checkServerError(rExchanges);

        const exchanges = pluckResponse(rExchanges, "exchanges");

        const result = {
            exchanges,
            queryParams
        }

        yield put(exchangeActions.apiResponseSuccess(ExchangeActionTypes.GET_EXCHANGES, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(exchangeActions.apiResponseError(ExchangeActionTypes.GET_EXCHANGES, error));
        } else {
            yield put(exchangeActions.apiResponseValidationErrors(ExchangeActionTypes.GET_EXCHANGES, error));
        }
    }
}

function* getExchange({ payload: { id } }: ExchangeData): SagaIterator {

    try {
        const param = {
            query:`query Exchange($id: String!) {
                exchange(id: $id) {
                    id
                    from {
                        name
                        balanceFloatNum
                        meta {
                            sign
                            currency
                        }
                        holder {
                            id
                            username
                            email
                        }
                    }
                    to {
                        name
                        balanceFloatNum
                        meta {
                            sign
                            currency
                        }
                    }
                    withdraw {
                        amount
                        amountFloat
                        meta {
                            description
                        }
                    }
                    deposit {
                        amount
                        amountFloat
                        meta {
                            description
                        }
                        rate
                        status
                    }
                    fee
                    feeFloat
                    status
                    created_at
                }
            }`,

            variables: {
                id: id
            },
            operationName: "Exchange"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ExchangeErrors.RESPONSE_200);

        const rE = response.data;

        checkServerError(rE);

        const exchange = pluckResponse(rE, "exchange");

        yield put(exchangeActions.apiResponseSuccess(ExchangeActionTypes.GET_EXCHANGE, exchange));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(exchangeActions.apiResponseError(ExchangeActionTypes.GET_EXCHANGE, error));
        } else {
            yield put(exchangeActions.apiResponseValidationErrors(ExchangeActionTypes.GET_EXCHANGE, error));
        }
    }
}

function* checkExchange({ payload: {data} }: ExchangeData): SagaIterator {
    try {
        const param = {

            query:`mutation CheckExchange(
                $wallet: String!,
                $amount: String!,
            ) {
                checkExchange(
                    wallet: $wallet,
                    amount: $amount,
                )
            }`,

            variables: { ...data },
            operationName: "CheckExchange"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ExchangeErrors.RESPONSE_200);

        const rCE = response.data;

        checkServerError(rCE);

        const checkExchange = pluckResponse(rCE, "checkExchange");

        yield put(exchangeActions.apiResponseSuccess(ExchangeActionTypes.CHECK_EXCHANGE, checkExchange));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(exchangeActions.apiResponseError(ExchangeActionTypes.CHECK_EXCHANGE, error));
        } else {
            yield put(exchangeActions.apiResponseValidationErrors(ExchangeActionTypes.CHECK_EXCHANGE, error));
        }
    }
}

function* insertExchange({ payload: {data} }: ExchangeData): SagaIterator {
    try {
        const param = {

            query:`mutation CreateExchange(
                $wallet: String!,
                $amount: String!,
                $twoFaOtp: String,
                $otp: String
            ) {
                createExchange(
                    wallet: $wallet,
                    amount: $amount,
                    twoFaOtp: $twoFaOtp,
                    otp: $otp
                )
            }`,

            variables: { ...data },
            operationName: "CreateExchange"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ExchangeErrors.RESPONSE_200);

        const rCE = response.data;

        checkServerError(rCE);

        const createExchange = pluckResponse(rCE, "createExchange");

        const api = new APICore();
        const user = api.getLoggedInUser();

        yield put(exchangeActions.getExchanges({
            page: 1,
            limit: 5,
            orderBy: "id",
            sort: "desc",
            accountId: user.permissions.includes("View All Exchanges")? "all": "",
        }));
        yield put(exchangeActions.apiResponseSuccess(ExchangeActionTypes.INSERT_EXCHANGE, createExchange));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(exchangeActions.apiResponseError(ExchangeActionTypes.INSERT_EXCHANGE, error));
        } else {
            yield put(exchangeActions.apiResponseValidationErrors(ExchangeActionTypes.INSERT_EXCHANGE, error));
        }
    }
}

function* searchExchange({ payload: { queryParams } }: ExchangeData): SagaIterator {
    try {
        const param = {
            query:`query Exchanges(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!,
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $exchangeId: String,
                $accountId: String,
                $transactionNames: [String]
            ) {
                exchanges(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort, 
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    exchangeId: $exchangeId,
                    accountId: $accountId,
                    transactionNames: $transactionNames
                ) {
                    data {
                        id
                        from {
                            name
                            balanceFloatNum
                            meta {
                                sign
                                currency
                            }
                            holder {
                                id
                                username
                                email
                            }
                        }
                        to {
                            name
                            balanceFloatNum
                            meta {
                                sign
                                currency
                            }
                        }
                        withdraw {
                            amountFloat
                            meta {
                                description
                            }
                        }
                        deposit {
                            amountFloat
                            meta {
                                description
                            }
                            rate
                        }
                        fee
                        feeFloat
                        status
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            },
            operationName: "Exchanges"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ExchangeErrors.RESPONSE_200);

        const rExchanges = response.data;

        checkServerError(rExchanges);

        const exchanges = pluckResponse(rExchanges, "exchanges");

        const result = {
            exchanges,
            queryParams
        }

        yield put(exchangeActions.apiResponseSuccess(ExchangeActionTypes.SEARCH_EXCHANGE, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(exchangeActions.apiResponseError(ExchangeActionTypes.SEARCH_EXCHANGE, error));
        } else {
            yield put(exchangeActions.apiResponseValidationErrors(ExchangeActionTypes.SEARCH_EXCHANGE, error));
        }
    }
}

function* getUsdRate(): SagaIterator {
    try {
        const param = {
            query:`query UsdRate {
                usdRate {
                    price,
                    created_at
                }
            }`,
            operationName: "UsdRate"
        };

        const response: any = yield call(graphql, param, '');

        checkResponseError(response, ExchangeErrors.RESPONSE_200);

        const rUR = response.data;

        checkServerError(rUR);

        const usdRate = pluckResponse(rUR, "usdRate");

        yield put(exchangeActions.apiResponseSuccess(ExchangeActionTypes.USD_RATE, usdRate || {}));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(exchangeActions.apiResponseError(ExchangeActionTypes.USD_RATE, error));
        } else {
            yield put(exchangeActions.apiResponseValidationErrors(ExchangeActionTypes.USD_RATE, error));
        }
    }
}

function* getTransactions({ payload: { queryParams } }: ExchangeData): SagaIterator {

    try {
        const param = {
            query:`query ExchangeTransactions($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $accountId: String) {
                exchangeTransactions(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId) {
                    data {
                        wallet {
                            uuid
                            name
                            holder {
                                id
                                email
                                username
                                display_name
                            }
                            meta {
                                sign
                                currency
                            }
                        }
                        type
                        amountFloat
                        balanceFloat
                        confirmed
                        meta {
                            description
                        }
                        name
                        transaction_id
                        thread_id
                        status
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            },
            operationName: "ExchangeTransactions"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ExchangeErrors.RESPONSE_200);

        const rTransactions = response.data;

        checkServerError(rTransactions);

        const transactions = pluckResponse(rTransactions, "exchangeTransactions");

        const result = {
            transactions,
            queryParams
        }

        yield put(exchangeActions.apiResponseSuccess(ExchangeActionTypes.GET_TRANSACTIONS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(exchangeActions.apiResponseError(ExchangeActionTypes.GET_TRANSACTIONS, error));
        } else {
            yield put(exchangeActions.apiResponseValidationErrors(ExchangeActionTypes.GET_TRANSACTIONS, error));
        }
    }
}

function* searchTransaction({ payload: { queryParams } }: ExchangeData): SagaIterator {
    try {
        const param = {
            query:`query ExchangeTransactions(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $wallet: String, 
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $transactionId: String,
                $threadId: String,
                $accountId: String,
                $transactionNames: [String],
                $transactionType: String
            ) {
                exchangeTransactions(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort, 
                    wallet: $wallet,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    transactionId: $transactionId,
                    threadId: $threadId,
                    accountId: $accountId,
                    transactionNames: $transactionNames,
                    transactionType: $transactionType
                ) {
                    data {
                        wallet {
                            uuid
                            name
                            holder {
                                id
                                email
                                username
                                display_name
                            }
                            meta {
                                sign
                                currency
                            }
                        }
                        type
                        amountFloat
                        balanceFloat
                        confirmed
                        meta {
                            description
                        }
                        name
                        transaction_id
                        thread_id
                        status
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            },
            operationName: "ExchangeTransactions"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ExchangeErrors.RESPONSE_200);

        const rTransactions = response.data;

        checkServerError(rTransactions);

        const transactions = pluckResponse(rTransactions, "exchangeTransactions");

        const result = {
            transactions,
            queryParams
        }

        yield put(exchangeActions.apiResponseSuccess(ExchangeActionTypes.SEARCH_TRANSACTION, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(exchangeActions.apiResponseError(ExchangeActionTypes.SEARCH_TRANSACTION, error));
        } else {
            yield put(exchangeActions.apiResponseValidationErrors(ExchangeActionTypes.SEARCH_TRANSACTION, error));
        }
    }
}

function* previewExchangeDetail({ payload: { data } }: ExchangeData): SagaIterator {
    try {
        const param = {
            query:`query PreviewExchangeDetail($wallet: String!, $amount: String!) {
                previewExchangeDetail(wallet: $wallet, amount: $amount) {
                    usdRate
                    subTotal
                    fee
                    total
                }
            }`,

            variables: {
                ...data
            },
            operationName: "PreviewExchangeDetail"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ExchangeErrors.RESPONSE_200);

        const rED = response.data;

        checkServerError(rED);

        const previewExchangeDetail = pluckResponse(rED, "previewExchangeDetail");

        yield put(exchangeActions.apiResponseSuccess(ExchangeActionTypes.PREVIEW_EXCHANGE_DETAIL, previewExchangeDetail));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(exchangeActions.apiResponseError(ExchangeActionTypes.PREVIEW_EXCHANGE_DETAIL, error));
        } else {
            yield put(exchangeActions.apiResponseValidationErrors(ExchangeActionTypes.PREVIEW_EXCHANGE_DETAIL, error));
        }
    }
}


function* exportTransactions({ payload: { queryParams } }: ExchangeData): SagaIterator {
    try {
        const param = {
            query:`query ExportExchangeTransactions(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $wallet: String, 
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $transactionId: String,
                $threadId: String,
                $accountId: String,
                $transactionNames: [String],
                $transactionType: String
            ) {
                exportExchangeTransactions(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort, 
                    wallet: $wallet,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    transactionId: $transactionId,
                    threadId: $threadId,
                    accountId: $accountId,
                    transactionNames: $transactionNames,
                    transactionType: $transactionType
                )
            }`,

            variables: {
                ...queryParams
            },
            operationName: "ExportExchangeTransactions"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ExchangeErrors.RESPONSE_200);

        const rTransactions = response.data;

        checkServerError(rTransactions);

        const transactions = pluckResponse(rTransactions, "exportExchangeTransactions");

        yield put(exchangeActions.apiResponseSuccess(ExchangeActionTypes.EXPORT_TRANSACTIONS, transactions));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(exchangeActions.apiResponseError(ExchangeActionTypes.EXPORT_TRANSACTIONS, error));
        } else {
            yield put(exchangeActions.apiResponseValidationErrors(ExchangeActionTypes.EXPORT_TRANSACTIONS, error));
        }
    }
}

function* exportExchanges({ payload: { queryParams } }: ExchangeData): SagaIterator {
    try {
        const param = {
            query:`query ExportExchanges(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!,
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $exchangeId: String,
                $accountId: String,
                $transactionNames: [String]
            ) {
                exportExchanges(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort, 
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    exchangeId: $exchangeId,
                    accountId: $accountId,
                    transactionNames: $transactionNames
                )
            }`,

            variables: {
                ...queryParams
            },
            operationName: "ExportExchanges"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ExchangeErrors.RESPONSE_200);

        const rTransactions = response.data;

        checkServerError(rTransactions);

        const exportExchanges = pluckResponse(rTransactions, "exportExchanges");

        yield put(exchangeActions.apiResponseSuccess(ExchangeActionTypes.EXPORT, exportExchanges));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(exchangeActions.apiResponseError(ExchangeActionTypes.EXPORT, error));
        } else {
            yield put(exchangeActions.apiResponseValidationErrors(ExchangeActionTypes.EXPORT, error));
        }
    }
}

export function* watchGetExchanges() {
    yield takeEvery(ExchangeActionTypes.GET_EXCHANGES, getExchanges);
}

export function* watchGetExchange() {
    yield takeEvery(ExchangeActionTypes.GET_EXCHANGE, getExchange);
}

export function* watchInsertExchange() {
    yield takeEvery(ExchangeActionTypes.INSERT_EXCHANGE, insertExchange);
}

export function* watchCheckExchange() {
    yield takeEvery(ExchangeActionTypes.CHECK_EXCHANGE, checkExchange);
}

export function* watchSearchExchange() {
    yield takeEvery(ExchangeActionTypes.SEARCH_EXCHANGE, searchExchange);
}

export function* watchGetUsdRate() {
    yield takeEvery(ExchangeActionTypes.USD_RATE, getUsdRate);
}

export function* watchGetTransactions() {
    yield takeEvery(ExchangeActionTypes.GET_TRANSACTIONS, getTransactions);
}

export function* watchSearchTransaction() {
    yield takeEvery(ExchangeActionTypes.SEARCH_TRANSACTION, searchTransaction);
}

export function* watchPreviewExchangeDetail() {
    yield takeLatest(ExchangeActionTypes.PREVIEW_EXCHANGE_DETAIL, previewExchangeDetail);
}

export function* watchExportTransactions() {
    yield takeEvery(ExchangeActionTypes.EXPORT_TRANSACTIONS, exportTransactions);
}

export function* watchExport() {
    yield takeEvery(ExchangeActionTypes.EXPORT, exportExchanges);
}

function* exchangesSaga() {
    yield all([
        fork(watchGetExchanges),
        fork(watchGetExchange),
        fork(watchCheckExchange),
        fork(watchInsertExchange),
        fork(watchSearchExchange),
        fork(watchGetUsdRate),
        fork(watchGetTransactions),
        fork(watchSearchTransaction),
        fork(watchPreviewExchangeDetail),
        fork(watchExportTransactions),
        fork(watchExport),
    ]);
}

export default exchangesSaga;
